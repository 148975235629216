<template>
    <div class="step-1 with-out-require-mark">
    <h1 class="step-title">
      欢迎您注册
    </h1>
    <form class="ant-form ant-form-horizontal">
      <div class="ant-row ant-form-item">
        <!-- <el-row>
          <el-form class="clearfix" ref="elForm1" :model="formData" :rules="rules" size="medium" label-width="100px">
            <el-col :style="{marginBottom: '20px'}" :span="24">
              <el-form-item label="手机号" prop="mobile">
                <el-input v-model="formData.mobile" placeholder="请输入手机号" clearable :style="{width: '100%'}"></el-input>
              </el-form-item>
            </el-col>
            <el-col :style="{marginBottom: '20px'}" :span="24">
              <el-form-item label="密码" prop="pwd">
                <el-input v-model="formData.pwd" show-password placeholder="请输入密码" clearable :style="{width: '100%'}"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
          <el-form class="clearfix" ref="elForm2" :model="formData" :rules="rules" size="medium" label-width="100px">
            <el-col :span="24" :style="{height: '60px'}">
              <el-form-item label="验证码" prop="code">
                <el-input v-model="formData.code" placeholder="获取短信验证码" :style="{width: '100%', borderRight: '1px solid transparent'}">
                </el-input>
              </el-form-item>
            </el-col>
            <div class="code_box">
              <span @click="getCode" class="code-btn">
                <b v-if="getCoding">获取短信验证码</b>
                <b v-else>{{tick}}秒后重新获取</b>
              </span>
            </div>
          </el-form>
        </el-row> -->
        <el-row :gutter="15">
          <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
            <el-form-item label="手机号" prop="mobile">
              <el-input v-model="formData.mobile" placeholder="请输入手机号" clearable :style="{width: '100%'}" @input="checkerRegisterUser">
              </el-input>
            </el-form-item>
            <el-col :span="24" class="code-item">
              <el-form-item label="验证码" prop="code">
                <el-input v-model="formData.code" placeholder="请输入验证码" clearable :style="{width: '100%'}">
                </el-input>
              </el-form-item>
              <el-form-item class="get-code-btn">
                <el-button type="success" size="medium" @click.stop="getMobileCode">
                  <span v-if="!getCoding" class="ng-binding">{{codeTime}}秒后重新获取</span>
                  <span v-else>
                    获取验证码
                  </span>
                </el-button>
              </el-form-item>
            </el-col>
            <!-- <el-col>
              <el-button style="width: 100%" type="success" class="submit" @click="submitForm">重置密码</el-button>
            </el-col> -->
            <el-form-item label="新密码" prop="passWord">
              <el-input v-model="formData.passWord" placeholder="请输入新密码" show-password clearable :style="{width: '100%'}">
              </el-input>
            </el-form-item>
            <div :class="['error-text', errorText ? 'show' : '']">{{errorText}}</div>
            <el-button type="success" class="submit" @click.stop="submit">注 册</el-button>
          </el-form>
        </el-row>
      </div>
      <!-- <div class="ant-row ant-form-item">
        <div>
          <div class="ant-form-item-control">
            <button @click="submit" type="button" class="ant-btn ant-btn-primary ant-btn-lg submit-btn">
              <span>
                注 册
              </span>
            </button>
          </div>
        </div>
      </div> -->
      <div class="ant-row ant-form-item confirm-btn-wrap">
        <div>
          <div class="ant-form-item-control has-success">
            <label class="ant-checkbox-wrapper">
              <span :class="['ant-checkbox', checked && 'ant-checkbox-checked']" @click="changeCheckbox">
                <input type="checkbox" id="agreement" class="ant-checkbox-input" />
                <span class="ant-checkbox-inner"></span>
              </span>
              <span>
                我已阅读并同意
              </span>
            </label>
            <a>《xxxx协议111》</a>
            <a>《xxxx协议222》</a>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { Register, GetCode, IsRegister } from '../api/login/api'
import Toast from './public/toast'
import Bus from '../bus'

export default {
  name: 'Singup',
  components: {},
  props: [],
  data() {
    return {
      errorText: '',
      timer: null,
      getCoding: true,
      checked: false,
      formData: {
        mobile: undefined,
        code: undefined,
        passWord: undefined,
      },
      rules: {
        mobile: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }, {
          pattern: /^1(3|4|5|7|8|9)\d{9}$/,
          message: '手机号格式错误',
          trigger: 'blur'
        }],
        passWord: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }, {
          pattern: /^\w{6,}$/,
          message: '密码格式错误',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }, {
          pattern: /^\d{4,6}$/,
          message: '验证码格式错误',
          trigger: 'blur'
        }],
      },
			getCoding: true,
      codeTime: 60,
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    Bus.$on('showSignupError', val => {
      this.errorText = val.msg;
    });
  },
  methods: {
    getMobileCode() {
      if(!(/^1(3|4|5|7|8|9)\d{9}$/).test(this.formData.mobile)) {
        this.$toast({ message: '请输入正确手机号', position: 'middle' })
        return
      }
      if(!this.getCoding) return;
      // this.getCoding = false;
      // this.ticketing();
      GetCode({
        mobile: this.formData.mobile
      }).then((res) => {
        if(res.code === 106) {

        } else {
          this.getCoding = false;
          this.ticketing();
        }
        this.$toast({ message: res.msg, position: 'middle' })
      })
    },
    ticketing() {
      this.timer = setInterval(() => {
        if(this.codeTime === 1) {
          this.getCoding = true;
          this.codeTime = 60;
          clearInterval(this.timer);
        }
        this.codeTime = --this.codeTime;
      }, 1000);
    },
    changeCheckbox() {
      this.checked = !this.checked;
    },
    getCode() {
      if(!this.getCoding) return;
      this.$refs['elForm'].validate(valid => {
        if(valid) {
          // this.getCoding = false;
          // this.ticketing();
          GetCode({
            mobile: this.formData.mobile
          }).then(data => {
            if(data.code === 106) {

            } else {
              this.getCoding = false;
              this.ticketing();
            }
            this.$toast({ message: data.msg, position: 'middle' })
          })
        }
      })
    },
    submit() {
      this.$refs['elForm'].validate(valid => {
        if(valid) {
          if(this.checked) {
            Register({
              code: this.formData.code,
              mobile: this.formData.mobile,
              passWord: this.formData.passWord
            }).then(data => {
              if(data.code === 0) {
                Bus.$emit('showSignup', false);
                Bus.$emit('showLogin', true);
              }
            })
          } else {
            Toast({ message: '请勾选协议', position: 'middle' })
          }
        }
      })
    },
    /*验证是否注册用户*/
    checkerRegisterUser() {
      // 验证this.formData.mobile是否是正确手机号
      let mobile = this.formData.mobile;
      if(!(/^1(3|4|5|7|8|9)\d{9}$/).test(mobile)) {
        this.errorText = '';
        return
      }
      IsRegister({
        mobile: mobile
      }).then(data => {
        this.errorText = data.msg;
      })
    }
  }
}

</script>
<style scoped>
.with-out-require-mark >>> .el-row,
.with-out-require-mark >>> .el-col {
  margin: 0 !important;
  padding: 0!important;
}
* {
  margin: 0
}

table,td,th,tr {
  font-size: 12px
}

ol,ul {
  list-style: none
}

li {
  list-style-type: none
}

img {
  vertical-align: top;
  border: 0
}

h1,h2,h3,h4,h5,h6 {
  font-size: inherit;
  font-weight: 400;
}

address,cite,code,em,i,th {
  font-weight: 400;
  font-style: normal
}

a {
  text-decoration: none;
  color: #666;
  cursor: pointer
}

a.noLink {
  cursor: default
}

a.noLink:hover {
  color: #666
}

a:hover {
  color: #ed0c1e
}

a,a:active,a:focus,a:hover,a:link,a:visited {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: none;
  text-decoration: none
}

body,html {
  -webkit-text-size-adjust: none;
  position: relative;
  height: 100%;
  min-width: 1366px
}

body {
  outline: none;
  padding: 0;
  font: 12px Microsoft Yahei,Arial,sans-serif;
  line-height: 1;
  color: #666;
  background: #fff
}


[class^=ant-] {
  box-sizing: border-box;
  line-height: 1.5
}
.ant-form-horizontal .ant-form-item {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  height: auto;
  zoom: 1;
}
.ant-form-item {
  overflow: hidden;
  margin-bottom: 30px;
}
.ant-row {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  height: auto;
  zoom: 1;
  display: block;
}
#userInfoName {
  font-size: 20px !important;
}
.ant-form-item {
  margin-bottom: 24px;
  color: #666;
}
.register-wrap .ant-col-5 {
    width: 17.8%;
}
.ant-col-5 {
    display: block;
    width: 20.83333333%;
}
.ant-col-1, .ant-col-2, .ant-col-3, .ant-col-4, .ant-col-5, .ant-col-6, .ant-col-7, .ant-col-8, .ant-col-9, .ant-col-10, .ant-col-11, .ant-col-12, .ant-col-13, .ant-col-14, .ant-col-15, .ant-col-16, .ant-col-17, .ant-col-18, .ant-col-19, .ant-col-20, .ant-col-21, .ant-col-22, .ant-col-23, .ant-col-24 {
    float: left;
    flex: 0 0 auto;
}
.ant-col-1, .ant-col-2, .ant-col-3, .ant-col-4, .ant-col-5, .ant-col-6, .ant-col-7, .ant-col-8, .ant-col-9, .ant-col-10, .ant-col-11, .ant-col-12, .ant-col-13, .ant-col-14, .ant-col-15, .ant-col-16, .ant-col-17, .ant-col-18, .ant-col-19, .ant-col-20, .ant-col-21, .ant-col-22, .ant-col-23, .ant-col-24, .ant-col-lg-1, .ant-col-lg-2, .ant-col-lg-3, .ant-col-lg-4, .ant-col-lg-5, .ant-col-lg-6, .ant-col-lg-7, .ant-col-lg-8, .ant-col-lg-9, .ant-col-lg-10, .ant-col-lg-11, .ant-col-lg-12, .ant-col-lg-13, .ant-col-lg-14, .ant-col-lg-15, .ant-col-lg-16, .ant-col-lg-17, .ant-col-lg-18, .ant-col-lg-19, .ant-col-lg-20, .ant-col-lg-21, .ant-col-lg-22, .ant-col-lg-23, .ant-col-lg-24, .ant-col-md-1, .ant-col-md-2, .ant-col-md-3, .ant-col-md-4, .ant-col-md-5, .ant-col-md-6, .ant-col-md-7, .ant-col-md-8, .ant-col-md-9, .ant-col-md-10, .ant-col-md-11, .ant-col-md-12, .ant-col-md-13, .ant-col-md-14, .ant-col-md-15, .ant-col-md-16, .ant-col-md-17, .ant-col-md-18, .ant-col-md-19, .ant-col-md-20, .ant-col-md-21, .ant-col-md-22, .ant-col-md-23, .ant-col-md-24, .ant-col-sm-1, .ant-col-sm-2, .ant-col-sm-3, .ant-col-sm-4, .ant-col-sm-5, .ant-col-sm-6, .ant-col-sm-7, .ant-col-sm-8, .ant-col-sm-9, .ant-col-sm-10, .ant-col-sm-11, .ant-col-sm-12, .ant-col-sm-13, .ant-col-sm-14, .ant-col-sm-15, .ant-col-sm-16, .ant-col-sm-17, .ant-col-sm-18, .ant-col-sm-19, .ant-col-sm-20, .ant-col-sm-21, .ant-col-sm-22, .ant-col-sm-23, .ant-col-sm-24, .ant-col-xs-1, .ant-col-xs-2, .ant-col-xs-3, .ant-col-xs-4, .ant-col-xs-5, .ant-col-xs-6, .ant-col-xs-7, .ant-col-xs-8, .ant-col-xs-9, .ant-col-xs-10, .ant-col-xs-11, .ant-col-xs-12, .ant-col-xs-13, .ant-col-xs-14, .ant-col-xs-15, .ant-col-xs-16, .ant-col-xs-17, .ant-col-xs-18, .ant-col-xs-19, .ant-col-xs-20, .ant-col-xs-21, .ant-col-xs-22, .ant-col-xs-23, .ant-col-xs-24 {
    position: relative;
    min-height: 1px;
    padding-left: 0;
    padding-right: 0;
}
.ant-form-item-label {
    text-align: right;
    vertical-align: middle;
    padding: 7px 0;
    display: inline-block;
}
.ant-form-horizontal .ant-form-item:after, .ant-form-horizontal .ant-form-item:before {
  content: " ";
  display: table;
}
.ant-row:after, .ant-row:before {
  content: " ";
  display: table;
}
[class^=ant-] {
    box-sizing: border-box;
    line-height: 1.5;
}
label {
    position: relative;
}
label {
    font-size: 12px;
}
label {
    cursor: default;
}
.ant-form-item-label label:after {
    content: ":";
    margin: 0 8px 0 2px;
    position: relative;
    top: -0.5px;
}
.ant-form-item label {
    font-size: 16px;
}
.ant-form-item-label label {
    color: #666;
}
.register-wrap .ant-col-19 {
    width: 82.2%;
}
.ant-col-19 {
    display: block;
    width: 79.16666667%;
}
.ant-col-1, .ant-col-2, .ant-col-3, .ant-col-4, .ant-col-5, .ant-col-6, .ant-col-7, .ant-col-8, .ant-col-9, .ant-col-10, .ant-col-11, .ant-col-12, .ant-col-13, .ant-col-14, .ant-col-15, .ant-col-16, .ant-col-17, .ant-col-18, .ant-col-19, .ant-col-20, .ant-col-21, .ant-col-22, .ant-col-23, .ant-col-24 {
    float: left;
    flex: 0 0 auto;
}
.ant-col-1, .ant-col-2, .ant-col-3, .ant-col-4, .ant-col-5, .ant-col-6, .ant-col-7, .ant-col-8, .ant-col-9, .ant-col-10, .ant-col-11, .ant-col-12, .ant-col-13, .ant-col-14, .ant-col-15, .ant-col-16, .ant-col-17, .ant-col-18, .ant-col-19, .ant-col-20, .ant-col-21, .ant-col-22, .ant-col-23, .ant-col-24, .ant-col-lg-1, .ant-col-lg-2, .ant-col-lg-3, .ant-col-lg-4, .ant-col-lg-5, .ant-col-lg-6, .ant-col-lg-7, .ant-col-lg-8, .ant-col-lg-9, .ant-col-lg-10, .ant-col-lg-11, .ant-col-lg-12, .ant-col-lg-13, .ant-col-lg-14, .ant-col-lg-15, .ant-col-lg-16, .ant-col-lg-17, .ant-col-lg-18, .ant-col-lg-19, .ant-col-lg-20, .ant-col-lg-21, .ant-col-lg-22, .ant-col-lg-23, .ant-col-lg-24, .ant-col-md-1, .ant-col-md-2, .ant-col-md-3, .ant-col-md-4, .ant-col-md-5, .ant-col-md-6, .ant-col-md-7, .ant-col-md-8, .ant-col-md-9, .ant-col-md-10, .ant-col-md-11, .ant-col-md-12, .ant-col-md-13, .ant-col-md-14, .ant-col-md-15, .ant-col-md-16, .ant-col-md-17, .ant-col-md-18, .ant-col-md-19, .ant-col-md-20, .ant-col-md-21, .ant-col-md-22, .ant-col-md-23, .ant-col-md-24, .ant-col-sm-1, .ant-col-sm-2, .ant-col-sm-3, .ant-col-sm-4, .ant-col-sm-5, .ant-col-sm-6, .ant-col-sm-7, .ant-col-sm-8, .ant-col-sm-9, .ant-col-sm-10, .ant-col-sm-11, .ant-col-sm-12, .ant-col-sm-13, .ant-col-sm-14, .ant-col-sm-15, .ant-col-sm-16, .ant-col-sm-17, .ant-col-sm-18, .ant-col-sm-19, .ant-col-sm-20, .ant-col-sm-21, .ant-col-sm-22, .ant-col-sm-23, .ant-col-sm-24, .ant-col-xs-1, .ant-col-xs-2, .ant-col-xs-3, .ant-col-xs-4, .ant-col-xs-5, .ant-col-xs-6, .ant-col-xs-7, .ant-col-xs-8, .ant-col-xs-9, .ant-col-xs-10, .ant-col-xs-11, .ant-col-xs-12, .ant-col-xs-13, .ant-col-xs-14, .ant-col-xs-15, .ant-col-xs-16, .ant-col-xs-17, .ant-col-xs-18, .ant-col-xs-19, .ant-col-xs-20, .ant-col-xs-21, .ant-col-xs-22, .ant-col-xs-23, .ant-col-xs-24 {
    position: relative;
    min-height: 1px;
    padding-left: 0;
    padding-right: 0;
}
.ant-form-item-control {
    line-height: 32px;
    position: relative;
    zoom: 1;
    font-size: 20px !important;
}
.ant-form-item-control:after, .ant-form-item-control:before {
    content: " ";
    display: table;
}
.ant-form-item-label {
    text-align: right;
    vertical-align: middle;
    padding: 7px 0;
    display: inline-block;
}
.ant-form-item {
    font-size: 12px;
    margin-bottom: 24px;
    color: #666;
}
.ant-input {
    position: relative;
    display: inline-block;
    padding: 4px 7px;
    width: 100%;
    height: 28px;
    cursor: text;
    font-size: 12px;
    line-height: 1.5;
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    transition: border .2s cubic-bezier(.645,.045,.355,1),background .2s cubic-bezier(.645,.045,.355,1),box-shadow .2s cubic-bezier(.645,.045,.355,1);
}
.ant-input {
    font-size: 16px;
    font-weight: 400;
    padding: 15px 10px;
    height: 50px !important;
    line-height: 50px !important;
    border: 1px solid #dedede;
    border-radius: 3px;
    box-shadow: none;
}
.ant-input-lg {
    padding: 6px 7px;
    height: 32px;
}
.ant-input::-moz-placeholder {
    color: #ccc;
    opacity: 1
}

.ant-input:-ms-input-placeholder {
    color: #ccc
}

.ant-input::-webkit-input-placeholder {
    color: #ccc
}

.ant-input:focus,.ant-input:hover {
    border-color: #858585
}

.ant-input:focus {
    outline: 0;
    box-shadow: 0 0 0 2px hsla(0,0%,40%,.2)
}

.ant-input[disabled],fieldset[disabled] .ant-input {
    background-color: #f7f7f7;
    opacity: 1;
    cursor: not-allowed;
    color: #ccc
}

.ant-input[disabled]:hover,fieldset[disabled] .ant-input:hover {
    border-color: #e1e1e1
}

.ant-btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    line-height: 1.5;
    padding: 4px 15px;
    font-size: 12px;
    border-radius: 6px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    position: relative;
    color: #666;
    background-color: #f7f7f7;
    border: 1px solid #d9d9d9;
}
.ant-btn {
    font-size: 16px;
    font-weight: 400;
    padding: 10px 42px;
    border-radius: 3px;
}
.ant-btn-primary {
    color: #fff;
    background-color: #666;
    border-color: #666;
}
.ant-btn-lg {
    padding: 4px 15px 5px;
    border-radius: 6px;
}
.ant-btn, .ant-btn:active, .ant-btn:focus {
    outline: 0;
}
.submit-btn {
    height: 46px;
    background: var(--main-color);
    width: 70%;
    margin: auto;
    display: block;
}
.ant-btn-primary, .ant-btn-primary:hover {
    color: #fff;
    background: var(--main-color);
    border: none;
}
form input[type=checkbox], form input[type=radio] {
    width: 14px;
    height: 14px;
}
input[type=checkbox], input[type=radio] {
    line-height: normal;
}
.ant-checkbox-input {
    position: absolute;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.step-1 .ant-checkbox .ant-checkbox-inner {
    width: 16px;
    height: 16px;
}
.step-1 >>> .el-form-item__label{
  font-size: 20px;
}
.ant-checkbox .ant-checkbox-inner {
    box-sizing: content-box;
    width: 12px;
    height: 12px;
}
.ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    top: 50%;
    transform: translate(0, 18%);
}
.ant-checkbox-inner:after {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
    position: absolute;
    left: 4px;
    top: 1px;
    display: table;
    width: 5px;
    height: 8px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    content: " ";
    transition: all .1s cubic-bezier(.71,-.46,.88,.6);
}
.step-1 {
  /* width: 520px; */
  margin-top: 20px;
  text-align: left;
  font-family: Microsoft Yahei,Arial,sans-serif;
}

.step-1 .step-title {
  color: #333;
  font-size: 28px;
  text-align: center;
  margin: 20px auto 40px;
}

.step-1 .confirm-btn-wrap {
  margin-top: -20px
}

.step-1 .confirm-btn-wrap label {
  /* font-size: 12px; */
  color: #333
}
.error-text {
  text-align: center;
  color: red;
  font-size: 20px;
  line-height: 60px;
  height: 60px;
  opacity: 0;
}
.error-text.show {
  opacity: 1;
}
.step-1 .confirm-btn-wrap a {
  color: #ed0c1e
}
.submit {
  margin: 0 auto;
  display: block;
  width: 50%;
}
.step-1 .confirm-btn-wrap .ant-checkbox+span {
  margin-left: 4px
}

.step-1 .code-btn {
  position: absolute;
  display: inline-block;
  right: 10px;
  top: 9px;
  color: var(--main-color);
  font-size: 16px;
  cursor: pointer
}

.step-1 .ant-form-item {
  position: relative;
  /* margin-bottom: 50px */
}

.step-1 .ant-form-item .ant-form-item-label {
  padding: 12px 0
}

.step-1 .ant-form-item-with-help {
  margin-bottom: 26px
}

.step-1 .ant-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px
}

.step-1 .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 8px
}
.el-input {
  font-size: 18px !important;
}
.step-1 >>> .el-input-group--append input {
  border-right: 1px solid transparent;
}
.step-1 >>> .el-input-group__append {
  background-color: transparent;
}
.code_box {
  /* position: absolute; */
  right: 10px;
  bottom: -180px;
  width: 140px;
}
.el-form {
  position: relative;
}
.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #f74848;
  background-color: #f74848;
}
.step-1 .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 8px;
}
.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner:after {
    left: 3px;
    top: -1px;
    width: 4px;
    height: 7px;
}
.ant-checkbox-checked .ant-checkbox-inner:after {
  -webkit-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  position: absolute;
  left: 4px;
  top: 1px;
  display: table;
  width: 5px;
  height: 8px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: " ";
  transition: all .2s cubic-bezier(.12,.4,.29,1.46) .1s;
}
.has-success {
  margin-top: 20px;
}
.ant-form-horizontal >>> .el-form-item__error {
  opacity: 0;
}
.code-item {
	padding: 0 !important;
  display: flex;
	align-items: center;
	height: 110px;
	position: relative;
	z-index: 999;
}
.code-item >>> .el-form-item {
  flex: 1;
}
.get-code-btn {
  margin-left: 10px;
  flex: 0 !important;
}
.get-code-btn >>> .el-form-item__content {
  margin-left: 0 !important;
}
</style>




