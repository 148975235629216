<template>
	<div class="container">
		<div class="person-left">
			<div class="persion-left-avtor">
				<img v-if="user.headImage" :src="user.headImage">
				<img v-else src="../assets/other/emoticon.png" />
				<div>
					<p>姓名：{{user.name}}</p>
					<b v-if="user.city">编号：{{user.code}}</b>
					<b v-else-if="user.positionId">职位：{{user.city}}</b>
					<b v-else>职位名称</b>
					<!-- <p @click="exit">退出登录</p> -->
				</div>
			</div>
			<div class="person-left-menu">
				<ul>
					<li v-for="(item, index) in menuList" :key="index" @click="changeTab(item, index)" :class="[index === currentIndex ? 'active' : '']"><i :class="[item.iconClass]"></i><span>{{item.text}}</span></li>
				</ul>
			</div>
		</div>
		<div class="person-right">
			<div v-if="currentIndex === 0" class="person-right-info">
				<div class="person-right-title">PDF收藏</div>
				<!-- <div class="change-type-tab">
					<span :class="[typeTab === 0 ? 'active' : '']" @click="changeTypeTab('collectList', 0)">pdf</span>
					<span :class="[typeTab === 1 ? 'active' : '']" @click="changeTypeTab('collectList', 1)">图纸</span>
				</div> -->
				<div v-if="pdfCollectList.length" class="brand_box clearfix">
					<div v-for="(item, index) in pdfCollectList" :key="index" @click="toDetail(item)" class="brand_item">
						<div class="brand_item_img">
							<img v-if="item.brandImage" class="brand_logo_img" :src="baseUrl + item.brandImage">
							<div v-else class="gap"></div>
							<img class="product_img" :src="baseUrl + item.imageUrl">
						</div>
						<div class="brand_div_title" :title="item.name">{{item.name}}</div>
						<div class="notes">{{item.subCatIdName}}</div>
						<div class="notes">
							<span>{{item.thirdCatIdName}}</span>
							<span>{{item.fourCatIdName}}</span>
						</div>
						<div class="brand_operate">
							<div>
								<i class="el-icon-view"></i>
								<p>{{item.browseCount}}</p>
							</div>
							<div>
								<i class="el-icon-download"></i>
								<p>{{item.downloadCount}}</p>
							</div>
							<div>
								<i class="el-icon-star-on"></i>
								<p>{{item.collectCount}}</p>
							</div>
							<div>
								<i class="el-icon-share"></i>
								<p>{{item.shareCount}}</p>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="no-data">{{noDataText}}</div>
        <paging v-if="total > pageSize" :total="total" @handleCurrentChange="handleCurrentChange"></paging>
			</div>
			<div v-if="currentIndex === 1" class="person-right-info">
				<div class="person-right-title">图纸收藏</div>
				<!-- <div class="change-type-tab">
					<span :class="[typeTab === 0 ? 'active' : '']" @click="changeTypeTab('downloadList', 0)">pdf</span>
					<span :class="[typeTab === 1 ? 'active' : '']" @click="changeTypeTab('downloadList', 1)">图纸</span>
				</div> -->
				<div v-if="canvasCollectList.length" class="brand_box clearfix">
					<div v-for="(item, index) in canvasCollectList" :key="index" @click="toCanvasDetail(item)" class="brand_item">
						<div class="brand_item_img">
							<img v-if="item.brandImage" class="brand_logo_img" :src="baseUrl + item.brandImage">
							<div v-else class="gap"></div>
							<img class="product_img" :src="baseUrl + item.imageUrl.split(',')[0]">
						</div>
						<div class="brand_div_title" :title="item.name">{{item.name}}</div>
						<div class="notes">{{item.subCatIdName}}</div>
						<div class="notes">
							<span>{{item.thirdCatIdName}}</span>
							<span>{{item.fourCatIdName}}</span>
						</div>
						<div class="brand_operate">
							<div>
								<i class="el-icon-view"></i>
								<p>{{item.browseCount}}</p>
							</div>
							<div>
								<i class="el-icon-download"></i>
								<p>{{item.downloadCount}}</p>
							</div>
							<div>
								<i class="el-icon-star-on"></i>
								<p>{{item.collectCount}}</p>
							</div>
							<div>
								<i class="el-icon-share"></i>
								<p>{{item.shareCount}}</p>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="no-data">{{noDataText}}</div>
        <paging v-if="total > pageSize" :total="total" @handleCurrentChange="handleCurrentChange"></paging>
			</div>
			<div v-if="currentIndex === 2" class="person-right-info">
				<div class="person-right-title">PDF下载</div>
				<!-- <div class="change-type-tab">
					<span :class="[typeTab === 0 ? 'active' : '']" @click="changeTypeTab('broweList', 0)">pdf</span>
					<span :class="[typeTab === 1 ? 'active' : '']" @click="changeTypeTab('broweList', 1)">图纸</span>
				</div> -->
				<div v-if="pdfDownloadList.length" class="brand_box clearfix">
					<div v-for="(item, index) in pdfDownloadList" :key="index" @click="toDetail(item)" class="brand_item">
						<div class="brand_item_img">
							<img v-if="item.brandImage" class="brand_logo_img" :src="baseUrl + item.brandImage">
							<div v-else class="gap"></div>
							<img class="product_img" :src="baseUrl + item.imageUrl">
						</div>
						<div class="brand_div_title" :title="item.name">{{item.name}}</div>
						<div class="notes">{{item.subCatIdName}}</div>
						<div class="notes">
							<span>{{item.thirdCatIdName}}</span>
							<span>{{item.fourCatIdName}}</span>
						</div>
						<div class="brand_operate">
							<div>
								<i class="el-icon-view"></i>
								<p>{{item.browseCount}}</p>
							</div>
							<div>
								<i class="el-icon-download"></i>
								<p>{{item.downloadCount}}</p>
							</div>
							<div>
								<i class="el-icon-star-on"></i>
								<p>{{item.collectCount}}</p>
							</div>
							<div>
								<i class="el-icon-share"></i>
								<p>{{item.shareCount}}</p>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="no-data">{{noDataText}}</div>
        <paging v-if="total > pageSize" :total="total" @handleCurrentChange="handleCurrentChange"></paging>
			</div>
			<div v-if="currentIndex === 3" class="person-right-info">
				<div class="person-right-title">图纸下载</div>
				<!-- <div class="change-type-tab">
					<span :class="[typeTab === 0 ? 'active' : '']" @click="changeTypeTab('collectList', 0)">pdf</span>
					<span :class="[typeTab === 1 ? 'active' : '']" @click="changeTypeTab('collectList', 1)">图纸</span>
				</div> -->
				<div v-if="canvasDownloadList.length" class="brand_box clearfix">
					<div v-for="(item, index) in canvasDownloadList" :key="index" @click="toCanvasDetail(item)" class="brand_item">
						<div class="brand_item_img">
							<img v-if="item.brandImage" class="brand_logo_img" :src="baseUrl + item.brandImage">
							<div v-else class="gap"></div>
							<img class="product_img" :src="baseUrl + item.imageUrl.split(',')[0]">
						</div>
						<div class="brand_div_title" :title="item.name">{{item.name}}</div>
						<div class="notes">{{item.subCatIdName}}</div>
						<div class="notes">
							<span>{{item.thirdCatIdName}}</span>
							<span>{{item.fourCatIdName}}</span>
						</div>
						<div class="brand_operate">
							<div>
								<i class="el-icon-view"></i>
								<p>{{item.browseCount}}</p>
							</div>
							<div>
								<i class="el-icon-download"></i>
								<p>{{item.downloadCount}}</p>
							</div>
							<div>
								<i class="el-icon-star-on"></i>
								<p>{{item.collectCount}}</p>
							</div>
							<div>
								<i class="el-icon-share"></i>
								<p>{{item.shareCount}}</p>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="no-data">{{noDataText}}</div>
        <paging v-if="total > pageSize" :total="total" @handleCurrentChange="handleCurrentChange"></paging>
			</div>
			<div v-if="currentIndex === 4" class="person-right-info">
				<div class="person-right-title">PDF浏览</div>
				<!-- <div class="change-type-tab">
					<span :class="[typeTab === 0 ? 'active' : '']" @click="changeTypeTab('downloadList', 0)">pdf</span>
					<span :class="[typeTab === 1 ? 'active' : '']" @click="changeTypeTab('downloadList', 1)">图纸</span>
				</div> -->
				<div v-if="pdfBroweList.length" class="brand_box clearfix">
					<div v-for="(item, index) in pdfBroweList" :key="index" @click="toDetail(item)" class="brand_item">
						<div class="brand_item_img">
							<img v-if="item.brandImage" class="brand_logo_img" :src="baseUrl + item.brandImage">
							<div v-else class="gap"></div>
							<img class="product_img" :src="baseUrl + item.imageUrl">
						</div>
						<div class="brand_div_title" :title="item.name">{{item.name}}</div>
						<div class="notes">{{item.subCatIdName}}</div>
						<div class="notes">
							<span>{{item.thirdCatIdName}}</span>
							<span>{{item.fourCatIdName}}</span>
						</div>
						<div class="brand_operate">
							<div>
								<i class="el-icon-view"></i>
								<p>{{item.browseCount}}</p>
							</div>
							<div>
								<i class="el-icon-download"></i>
								<p>{{item.downloadCount}}</p>
							</div>
							<div>
								<i class="el-icon-star-on"></i>
								<p>{{item.collectCount}}</p>
							</div>
							<div>
								<i class="el-icon-share"></i>
								<p>{{item.shareCount}}</p>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="no-data">{{noDataText}}</div>
        <paging v-if="total > pageSize" :total="total" @handleCurrentChange="handleCurrentChange"></paging>
			</div>
			<div v-if="currentIndex === 5" class="person-right-info">
				<div class="person-right-title">图纸浏览</div>
				<!-- <div class="change-type-tab">
					<span :class="[typeTab === 0 ? 'active' : '']" @click="changeTypeTab('broweList', 0)">pdf</span>
					<span :class="[typeTab === 1 ? 'active' : '']" @click="changeTypeTab('broweList', 1)">图纸</span>
				</div> -->
				<div v-if="canvasBroweList.length" class="brand_box clearfix">
					<div v-for="(item, index) in canvasBroweList" :key="index" @click="toCanvasDetail(item)" class="brand_item">
						<div class="brand_item_img">
							<img v-if="item.brandImage" class="brand_logo_img" :src="baseUrl + item.brandImage">
							<div v-else class="gap"></div>
							<img class="product_img" :src="baseUrl + item.imageUrl.split(',')[0]">
						</div>
						<div class="brand_div_title" :title="item.name">{{item.name}}</div>
						<div class="notes">{{item.subCatIdName}}</div>
						<div class="notes">
							<span>{{item.thirdCatIdName}}</span>
							<span>{{item.fourCatIdName}}</span>
						</div>
						<div class="brand_operate">
							<div>
								<i class="el-icon-view"></i>
								<p>{{item.browseCount}}</p>
							</div>
							<div>
								<i class="el-icon-download"></i>
								<p>{{item.downloadCount}}</p>
							</div>
							<div>
								<i class="el-icon-star-on"></i>
								<p>{{item.collectCount}}</p>
							</div>
							<div>
								<i class="el-icon-share"></i>
								<p>{{item.shareCount}}</p>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="no-data">{{noDataText}}</div>
        <paging v-if="total > pageSize" :total="total" @handleCurrentChange="handleCurrentChange"></paging>
			</div>
			<div v-if="currentIndex === 6" class="person-right-info">
				<div class="person-right-title">我的发言</div>
					<div class="answer-width">
						<div class="com-title">我的评论</div>
						<div class="my-answer">
							<ul v-if="dataList1.length">
								<li v-for="(item, index) in dataList1" :key="index">
              		<img :src="item.headImage">
									<div>
										<p v-html="item.content"></p>
										<div v-html="item.content"></div>
										<div>
											<span>{{ parseTime(item.createTime, "{y}-{m}-{d}")}}</span>
										</div>
									</div>
									<el-button type="success" size="small" @click="removeDissItem(item)">删除</el-button>
								</li>
							</ul>
							<div v-else class="no-data">{{noDataText}}</div>
						</div>
						<div class="com-title">我的需求</div>
						<div v-if="dataList2.length" class="my-question">
							<div class="product_discussion_content_item" v-for="(item, index) in dataList2" :key="index">
								<div>
									<div class="content_item_title">
										<div>{{item.title}}</div>
										<template v-if="item.label">
											<div class="label" v-for="(item2,index2) in item.label.split(',')" :key="index2">
												<div>{{item2}}</div><div></div>
											</div>
										</template>
									</div>
									<div class="content_item_bottom">
										<span><img :src="item.headImage">{{item.userName }}</span>
										<span>{{ parseTime(item.createTime, "{y}-{m}-{d}")}}</span>
										<span><i class="el-icon-view"></i>{{item.browseCount}}</span>
										<span><i class="el-icon-chat-line-square"></i>{{item.commentCount}}</span>
									</div>
								</div>
								<div class="content_item_left">
									<el-button type="success" size="small" @click="toEditItem(item)">修改</el-button>
								</div>
								<div class="content_item_left">
									<el-button type="success" size="small" @click="toDissDetail(item)">浏览</el-button>
								</div>
							</div>
						</div>
						<div v-else class="no-data">{{noDataText}}</div>
					</div>
			</div>
			<div v-if="currentIndex === 7" class="person-right-info">
				<div class="person-right-title">修改个人信息</div>
				<div class="com-width">
					<el-row :gutter="15">
						<el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
							<el-form-item label="姓名" prop="name">
								<el-input v-model="formData.name" placeholder="请输入姓名" clearable :style="{width: '100%'}">
								</el-input>
							</el-form-item>
							<el-form-item label="公司名称" prop="companyName">
								<el-input v-model="formData.companyName" placeholder="请输入公司名称" clearable :style="{width: '100%'}">
								</el-input>
							</el-form-item>
							<el-form-item label="Email" prop="email">
								<el-input v-model="formData.email" placeholder="请输入Email" clearable :style="{width: '100%'}">
								</el-input>
							</el-form-item>
							<!-- <el-form-item label="手机号" prop="mobile">
								<el-input v-model="formData.mobile" placeholder="请输入手机号" clearable :style="{width: '100%'}">
								</el-input>
							</el-form-item> -->
							<el-form-item label="职位" prop="positionId">
								<el-select v-model="formData.positionId" @change="positionIdChage" placeholder="请选择职位" clearable :style="{width: '100%'}">
									<el-option v-for="(item, index) in positionIdOptions" :key="index" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="个人职称或专业" prop="technicalFieldId">
								<el-select v-model="formData.technicalFieldId" multiple @change="technicalFieldIdChage" placeholder="请选择技术领域" clearable :style="{width: '100%'}">
									<el-option v-for="(item, index) in technicalFieldOptions" :key="index" :label="item.label" :value="item.value + ''">
										<span>{{ item.label }}</span>
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="现任职公司所属行业" prop="applicationAreaId">
								<el-select v-model="formData.applicationAreaId" multiple @change="applicationAreaIdChage" placeholder="请选择应用领域" clearable :style="{width: '100%'}">
									<el-option v-for="(item, index) in applicationAreaOptions" :key="index" :label="item.label" :value="item.value + ''">
										<span>{{ item.label }}</span>
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="省" prop="province">
								<el-select v-model="formData.province" @change="proviceChage" placeholder="请选择省" clearable :style="{width: '100%'}">
									<el-option v-for="(item, index) in provinceOptions" :key="index" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="市" prop="city">
								<el-select v-model="formData.city" @change="cityChage" placeholder="请选择市" clearable :style="{width: '100%'}">
									<el-option v-for="(item, index) in cityOptions" :key="index" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<!-- <el-form-item label="区" prop="district">
								<el-select v-model="formData.district" placeholder="请选择区" clearable :style="{width: '100%'}">
									<el-option v-for="(item, index) in districtOptions" :key="index" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item> -->
							<el-button type="success" class="submit" @click="submitForm">发送</el-button>
						</el-form>
					</el-row>
				</div>
			</div>
			<div v-if="currentIndex === 8" class="person-right-info">
				<div class="person-right-title">修改密码</div>
				<div class="com-width">
					<el-row :gutter="15">
						<el-form ref="elForm2" :model="formData2" :rules="rules2" size="medium" label-width="100px">
							<!-- <el-form-item label="姓名" prop="userName2">
								<el-input v-model="formData2.userName2" placeholder="请输入姓名" clearable :style="{width: '100%'}">
								</el-input>
							</el-form-item>
							<el-form-item label="密码" prop="pwd">
								<el-input v-model="formData2.pwd" placeholder="请输入密码" clearable :style="{width: '100%'}">
								</el-input>
							</el-form-item> -->
							<el-form-item label="手机号" prop="mobile">
								<el-input v-model="formData2.mobile" placeholder="请输入手机号" clearable :style="{width: '100%'}">
								</el-input>
							</el-form-item>
							<el-col :span="24" class="code-item">
								<el-form-item label="验证码" prop="code">
									<el-input v-model="formData2.code" placeholder="请输入验证码" clearable :style="{width: '100%'}">
									</el-input>
								</el-form-item>
								<el-form-item class="get-code-btn">
									<el-button type="success" size="medium" @click="getMobileCode">
										<span v-if="!getCoding" class="ng-binding">{{codeTime}}秒后重新获取</span>
										<span v-else>
											获取验证码
										</span>
									</el-button>
								</el-form-item>
							</el-col>
							<!-- <el-col>
								<el-button style="width: 100%" type="success" class="submit" @click="submitForm">重置密码</el-button>
							</el-col> -->
							<el-form-item label="新密码" prop="newPwd1">
								<el-input v-model="formData2.newPwd1" placeholder="请输入新密码" show-password clearable :style="{width: '100%'}">
								</el-input>

							</el-form-item>
							<el-form-item label="确认密码" prop="newPwd2">
								<el-input v-model="formData2.newPwd2" placeholder="请输入确认密码" show-password clearable :style="{width: '100%'}">
								</el-input>
							</el-form-item>
							<el-button type="success" class="submit" @click="submitForm2">发送</el-button>
						</el-form>
					</el-row>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { baseUrl } from '../utils'
import { GetCode, ResetPwd, GetUserInfo } from '../api/login/api'
import paging from './public/paging.vue'
import { List } from '../api/productDiscussion/api.js';
import { MyMessageInfoCommentList, MyMessageInfoList, DeleteMessageInfoComment, MyBroweList, MyCollectList, MyDownLoadList, MyBroweList2, MyCollectList2, MyDownLoadList2, GetDict, UpdateUserInfo } from '../api/person/api'
import { CompanyAdd, GetProvinceList, GetCityList, GetCountyList } from '../api/company/api'
export default {
	name: 'Person',
	data() {
		return {
			noDataText: '暂无数据',
			getCoding: true,
      codeTime: 60,
			dataList1: [],
			dataList2: [],
			user: {},
			currentIndex: 0,
			typeTab: 0,
			baseUrl,
			collectList: [],
			pdfCollectList: [],
			canvasCollectList: [],
			downloadList: [],
			pdfDownloadList: [],
			canvasDownloadList: [],
			broweList: [],
			pdfBroweList: [],
			canvasBroweList: [],
			total: 0,
			pdfTotal: 0,
			canvasTotal: 0,
			currentPage: 0,
			collectCurrentPage: 1,
			broweCurrentPage: 1,
			downloadCurrentPage: 1,
			pageSize: 16,
			menuList: [
				{
					iconClass: 'pdf-collection',
					text: 'PDF收藏'
				},
				{
					iconClass: 'canvas-collection',
					text: '图纸收藏'
				},
				{
					iconClass: 'pdf-download',
					text: 'PDF下载'
				},
				{
					iconClass: 'canvas-download',
					text: '图纸下载'
				},
				{
					iconClass: 'pdf-view',
					text: 'PDF浏览'
				},
				{
					iconClass: 'canvas-view',
					text: '图纸浏览'
				},
				{
					iconClass: 'my-msg',
					text: '我的发言'
				},
				{
					iconClass: 'edit-msg',
					text: '修改个人信息'
				},
				{
					iconClass: 'chang-pwd',
					text: '修改密码'
				},
				{
					iconClass: 'logout',
					text: '退出登录'
				}
			],
			formData: {
				companyName: undefined,
				email: undefined,
				mobile: undefined,
				code: undefined,
				name: undefined,
				province: undefined,
				city: undefined,
				district: undefined,
				positionId: undefined,
				technicalFieldId: [],
				applicationAreaId: [],
			},
			formData2: {
				mobile: undefined,
				code: undefined,
				newPwd1: undefined,
				newPwd2: undefined,
			},
			rules2: {
				mobile: [{
          required: true,
          validator: (rule, value, callback) => {
            const regExp = /^(0|86|17951)?(1[1-9])[0-9]{9}$/
            if (!regExp.test(value)) {
              callback(new Error('请输入正确的手机号'))
            } else {
              callback()
            }
          },
          message: '请输入手机号',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }],
				newPwd1: [{
					required: true,
					message: '请输入密码',
					trigger: 'blur'
				}],
				newPwd2: [{
					required: true,
					message: '请输入密码',
					trigger: 'blur'
				}],
			},
			rules: {
				name: [{
					required: true,
					message: '请输入姓名',
					trigger: 'blur'
				}],
				email: [{
					required: true,
					message: '请输入Email',
					validator: (rule, value, callback) => {
						const regExp = /^.+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
						if (!regExp.test(value)) {
							callback(new Error('请输入正确的邮箱'))
						} else {
							callback()
						}
					},
					trigger: 'blur'
				}],
				// mobile: [{
				// 	required: true,
				// 	validator: (rule, value, callback) => {
				// 		const regExp = /^(0|86|17951)?(1[1-9])[0-9]{9}$/
				// 		if (!regExp.test(value)) {
				// 			callback(new Error('请输入正确的手机号'))
				// 		} else {
				// 			callback()
				// 		}
				// 	},
				// 	message: '请输入手机号',
				// 	trigger: 'blur'
				// }],
				companyName: [{
					required: true,
					message: '请输入公司名称',
					trigger: 'blur'
				}],
				positionId: [{
					required: true,
					type: 'number',
					message: '请至少选择一个职位',
					trigger: 'blur'
				}],
				technicalFieldId: [{
					required: true,
					type: 'array',
					message: '请至少选择一个技术领域',
					trigger: 'blur'
				}],
				applicationAreaId: [{
					required: true,
					type: 'array',
					message: '请至少选择一个应用领域',
					trigger: 'blur'
				}],
				// province: [{
				// 	required: true,
				// 	type: 'string',
				// 	message: '请至少选择一个省',
				// 	trigger: 'blur'
				// }],
				// city: [{
				// 	required: true,
				// 	type: 'string',
				// 	message: '请至少选择一个市',
				// 	trigger: 'blur'
				// }],
				// district: [{
				// 	required: true,
				// 	type: 'string',
				// 	message: '请至少选择一个区',
				// 	trigger: 'blur'
				// }],
			},
			provinceOptions: [],
			cityOptions: [],
			districtOptions: [],
			positionIdOptions: [],
			technicalFieldOptions: [],
			applicationAreaOptions: []
		}
	},
	components: {
		paging
	},
	methods: {
    getMobileCode() {
      if(!(/^1(3|4|5|7|8|9)\d{9}$/).test(this.formData2.mobile)) {
        this.$toast({ message: '请输入正确手机号', position: 'middle' })
        return
      }
      if(!this.getCoding) return;
      GetCode({
        mobile: this.formData2.mobile
      }).then((res) => {
				if(res.code === 106) {

				} else {
					this.getCoding = false;
      		this.ticketing();
				}
        this.$toast({ message: res.msg, position: 'middle' })
      })
    },
    ticketing() {
      this.timer = setInterval(() => {
        if(this.codeTime === 1) {
          this.getCoding = true;
          this.codeTime = 60;
          clearInterval(this.timer);
        }
        this.codeTime = --this.codeTime;
      }, 1000);
    },
		toEditItem(item) {
			const route = this.$router.resolve({
        name: 'ProductDiscussion',
        query:{
          question: 2,
          uuid: item.uuid
        }
      })
      window.open(route.href, '_blank');
		},
		toDissDetail(item) {
			const route = this.$router.resolve({
        name: 'ProductDiscussion',
        query:{
          question: 3,
          uuid: item.uuid
        }
      })
      window.open(route.href, '_blank');
		},
		removeDissItem(item) {
			console.log('删除发言', item)
			DeleteMessageInfoComment({
				id: item.id
			}).then(res => {
				console.log('res', res);
				this.$toast({ message: res.msg, position: 'middle' })
				MyMessageInfoCommentList({
					pageNum: 1,
					pageSize: 100
				}).then(res => {
					this.dataList1 = res.list;
				})
			})
		},
		selectList() {
      // List({msgType: 0, pageNum: this.currentPage, pageSize: this.pageSize}).then(res => {
      //   this.dataList = res.list;
      // });
			MyMessageInfoCommentList({
				pageNum: 1,
				pageSize: 100
			}).then(res => {
				this.dataList1 = res.list;
			})
			MyMessageInfoList({
				pageNum: 1,
				pageSize: 100
			}).then(res => {
				this.dataList2 = res.list;
			})
    },
		changeTypeTab(type, index) {
			this.typeTab = index;
			if(type === 'collectList') {
				this.collectList = index === 0 ? [...this.pdfCollectList] : [...this.canvasCollectList];
				this.total = index === 0 ? this.pdfTotal : this.canvasTotal;
			} else if(type === 'downloadList') {
				this.downloadList = index === 0 ? [...this.pdfDownloadList] : [...this.canvasDownloadList];
				this.total = index === 0 ? this.pdfTotal : this.canvasTotal;
			} else if(type === 'broweList') {
				this.broweList = index === 0 ? [...this.pdfBroweList] : [...this.canvasBroweList];
				this.total = index === 0 ? this.pdfTotal : this.canvasTotal;
			}
		},
		isLogin() {
			return !!(window.localStorage && window.localStorage.getItem('token'))
		},
		exit() {
			window.localStorage && window.localStorage.removeItem('user');
			window.localStorage && window.localStorage.removeItem('token');
			window.location.reload();
		},
		getTechnicalFieldOptions() {
			GetDict({code: 'technical_field'}).then(data => {
				let { list } = data;
				list.forEach(item => {
          item.value = item.id
					// item.value = item.name
					item.label = item.name
				});
        this.technicalFieldOptions = [...list];
				let IDArr = this.user.technicalFieldId.split(',');
				IDArr = IDArr.map(item => {
					return parseInt(item);
				})
				let current = list.filter(item => IDArr.indexOf(item.name) > -1 )
				if(current.length) {
					this.formData = {
						...this.formData,
						technicalFieldId: [...current.map(item => item.name)]
					}
				}
      });
		},
		getApplicationAreaOptions() {
			GetDict({code: 'application_area'}).then(data => {
				let { list } = data;
				list.forEach(item => {
					item.value = item.id
					// item.value = item.name
					item.label = item.name
				});
        this.applicationAreaOptions = [...list];
				let IDArr = this.user.applicationAreaId.split(',');
				IDArr = IDArr.map(item => {
					return parseInt(item);
				})
				let current = list.filter(item => IDArr.indexOf(item.name) > -1 )
				if(current.length) {
					this.formData = {
						...this.formData,
						applicationAreaId: [...current.map(item => item.name)]
					}
				}
      });
		},
		getPositionOptions() {
			GetDict({code: 'position'}).then(data => {
				let { list } = data;
				list.forEach(item => {
					item.value = item.id
					item.label = item.name
				});
        this.positionIdOptions = [...list];
				let current = list.filter(item => item.id == this.user.positionId)
				if(current.length) {
					this.formData = {
						...this.formData,
						positionId: current[0].id
					}
				}
      });
		},
		toDetail(item) {
			window.open(window.location.origin + '#/Pdf/PdfDetail?uuid=' + item.uuid)
		},
		toCanvasDetail(item) {
			window.open(window.location.origin + '#/Canvas/CanvasDetail?uuid=' + item.uuid)
		},
		changeTab(item, index) {
			this.currentIndex = index;
			this.typeTab = 0;
			if(index === 0) {
				this.getMyCollectList();
			} else if(index === 1) {
				this.getMyCollectList2();
			} else if(index === 2) {
				this.getMyDownLoadList();
			} else if(index === 3) {
				this.getMyDownLoadList2();
			} else if(index === 4) {
				this.getMyBroweList();
			} else if(index === 5) {
				this.getMyBroweList2();
			} else if(index === 6) {
				this.selectList()
			} else if(index === 7) {
				this.getProvinceOptions();
				this.getTechnicalFieldOptions();
				this.getPositionOptions();
				this.getApplicationAreaOptions();
			} else if(index === 9) {
				this.exit();
			}
		},
    handleCurrentChange(value) {
			this.currentPage = value;
			if(this.currentIndex === 0) {
      	// this.collectCurrentPage = value;
				this.getMyCollectList();
			} else if(this.currentIndex === 1) {
      	// this.downloadCurrentPage = value;
				this.getMyCollectList2();
			} else if(this.currentIndex === 2) {
      	// this.broweCurrentPage = value;
      	this.getMyDownLoadList();
			} else if(this.currentIndex === 3) {
      	// this.collectCurrentPage = value;
				this.getMyDownLoadList2();
			} else if(this.currentIndex === 4) {
      	// this.downloadCurrentPage = value;
				this.getMyBroweList();
			} else if(this.currentIndex === 5) {
      	// this.broweCurrentPage = value;
      	this.getMyBroweList2();
			}
    },
		getMyBroweList() {
			MyBroweList({
				pageSize: this.pageSize,
				pageNum: this.currentPage
			}).then(data => {
				this.pdfBroweList = [...data.list];
        this.total = data.total;
			})
		},
		getMyBroweList2() {
			MyBroweList2({
				pageSize: this.pageSize,
				pageNum: this.currentPage
			}).then(data => {
				this.canvasBroweList = [...data.list];
        this.total = data.total;
			})
		},
		getMyCollectList() {
			MyCollectList({
				pageSize: this.pageSize,
				pageNum: this.currentPage
			}).then(data => {
				this.pdfCollectList = [...data.list];
        this.total = data.total;
				// if(this.typeTab === 0) {
				// 	this.collectList = [...data.list]
				// }
			})
		},
		getMyCollectList2() {
			MyCollectList2({
				pageSize: this.pageSize,
				pageNum: this.currentPage
			}).then(data => {
				this.canvasCollectList = [...data.list];
        this.total = data.total;
				// if(this.typeTab === 0) {
				// 	this.collectList = [...data.list]
				// }
			})
		},
		getMyDownLoadList() {
			MyDownLoadList({
				pageSize: this.pageSize,
				pageNum: this.currentPage
			}).then(data => {
				this.pdfDownloadList = [...data.list];
        this.total = data.total;
			})
		},
		getMyDownLoadList2() {
			MyDownLoadList2({
				pageSize: this.pageSize,
				pageNum: this.currentPage
			}).then(data => {
				this.canvasDownloadList = [...data.list];
        this.total = data.total;
			})
		},
		proviceChage() {
			this.getCityOptions();
		},
		cityChage() {
			this.getDistrictOptions();
		},
		positionIdChage() {},
		technicalFieldIdChage() {
			console.log()
		},
		applicationAreaIdChage() {
			console.log()
		},
		submitForm() {
			this.$refs['elForm'].validate(valid => {
				if (!valid) return
				UpdateUserInfo({
					...this.formData,
					// name: this.formData.name, // 姓名
					applicationAreaId: this.formData.applicationAreaId.join(','),
					technicalFieldId: this.formData.technicalFieldId.join(','),
				}).then(() => {
					// this.dialogVisible = true;
					this.$toast({ message: '修改成功', position: 'middle' })
					window.location.reload();
				})
			})
		},
		submitForm2() {
			this.$refs['elForm2'].validate(valid => {
        if (!valid) return
				if(this.formData2.newPwd1 !== this.formData2.newPwd2) {
					this.$toast({ message: '两次密码不一致，请重新输入', position: 'middle' })
					return;
				}
        ResetPwd({
          code: this.formData2.code,
          mobile: this.formData2.mobile,
          passWord: this.formData2.newPwd1
        }).then(() => {
          // this.$toast({ message: '修改成功', position: 'middle' })
					this.$alert('', '密码修改成功', {
						confirmButtonText: '确定'
					});
					this.formData2 = {
						code: undefined,
						mobile: undefined,
						newPwd1: undefined,
						newPwd2: undefined,
					}
					this.getCoding = true;
					this.codeTime = 60;
        })
      })
		},
		getUserInfo() {
			const token = window.localStorage && window.localStorage.getItem('token')
      GetUserInfo({
        'auth-token': token
      }).then(data => {
				this.user = {...data};
				this.formData = {
					...this.formData,
					name: data.name,
					companyName: data.companyName,
					email: data.email,
					mobile: data.mobile,
					applicationAreaId: data.applicationAreaId.split(','),
					technicalFieldId: data.technicalFieldId.split(',')
				}
      })
    },
		getProvinceOptions() {
			GetProvinceList().then(data => {
				data.forEach(item => {
					item.value = item.id
					item.label = item.name
				});
				this.provinceOptions = [...data];
				this.cityOptions = [];
				this.districtOptions = [];
				let current = data.filter(item => item.id == this.user.province)
				if(current.length) {
					this.formData = {
						...this.formData,
						province: current[0].id,
					}
					this.getCityOptions();
				}
			});
		},
		getCityOptions() {
			GetCityList({
				provinceId: this.formData.province
			}).then(data => {
				data.forEach(item => {
					item.value = item.id
					item.label = item.name
				});
				this.cityOptions = [...data];
				this.districtOptions = [];
				let current = data.filter(item => item.id == this.user.city)
				if(current.length) {
					this.formData = {
						...this.formData,
						city: current[0].id,
						district: undefined
					}
					this.getDistrictOptions();
				}
			});
		},
		getDistrictOptions() {
			let provinceFilter = this.provinceOptions.filter(item => item.value === this.formData.province);
			let cityFilter = this.cityOptions.filter(item => item.value === this.formData.city);
			GetCountyList({
				provinceId: this.formData.province,
				cityCode: this.formData.city,
				provinceName: provinceFilter.length && provinceFilter[0].value,
				cityName: cityFilter.length && cityFilter[0].value
			}).then(data => {
				data.forEach(item => {
					item.value = item.id
					item.label = item.name
				});
				this.districtOptions = [...data];
				let current = data.filter(item => item.id == this.user.district)
				if(current.length) {
					this.formData = {
						...this.formData,
						district: current[0].id
					}
				}
			});
		},
	},
	mounted() {
		if(this.isLogin()) {
			this.getMyCollectList();
			this.getUserInfo();
			window.addEventListener('setItemEvent', (e) => {
				if(e.key === 'user') {
					this.getUserInfo();
				}
			})
		} else {
			this.$router.push('/')
		}
	},
	computed: {
	},
}
</script>

<style scoped>
.container {
	background-color: #e8e8e8;
	display: flex;
	color: #8a8a8a;
}
.person-left {
	width: 324px;
	padding: 20px 14px 0;
}
.person-right {
	flex: 1;
}
.persion-left-avtor {
	margin: 0 auto 20px;
	display: flex;
	padding: 30px 16px 30px 50px;
	align-items: center;
	/* justify-content: center; */
	border-bottom: 2px solid #dedede;
}
.persion-left-avtor div {
	font-size: 12px;
	font-family: Microsoft YaHei;
}
.persion-left-avtor div p {
	height: 18px;
	line-height: 18px;
	padding: 15px 0;
	font-size: 18px;
	font-weight: bold;
}
.persion-left-avtor img {
	width: 64px;
	height: 64px;
	border-radius: 50%;
	margin-right: 16px;
}
.person-left-menu {
	font-family: Microsoft YaHei;
	text-align: center;
}
.person-left-menu ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.person-left-menu li {
	border-bottom: 1px solid #dedede;
	height: 60px;
	display: flex;
	align-items: center;
	padding-left: 50px;
	/* justify-content: center; */
	cursor: pointer;
}
.person-left-menu li span {
	font-family: Microsoft YaHei;
	font-size: 18px;
	vertical-align: middle;
}
.person-left-menu li.active span {
	color: var(--main-color);
}
.person-left-menu li i {
	/* font-size: 20px; */
	color: var(--main-color);
	margin-right: 18px;
	width: 20px;
	height: 20px;
}
.person-left-menu .pdf-collection {
  	background: url('../assets/icon/pdf-collection.png') center center no-repeat;
	background-size: cover;
}
.person-left-menu .canvas-collection {
  	background: url('../assets/icon/canvas-collection.png') center center no-repeat;
	background-size: cover;
}
.person-left-menu .pdf-download {
  	background: url('../assets/icon/pdf-download.png') center center no-repeat;
	background-size: cover;
}
.person-left-menu .canvas-download {
  	background: url('../assets/icon/canvas-download.png') center center no-repeat;
	background-size: cover;
}
.person-left-menu .pdf-view {
  	background: url('../assets/icon/pdf-view.png') center center no-repeat;
	background-size: cover;
}
.person-left-menu .canvas-view {
  	background: url('../assets/icon/canvas-view.png') center center no-repeat;
	background-size: cover;
}
.person-left-menu .my-msg {
  	background: url('../assets/icon/my-msg.png') center center no-repeat;
	background-size: cover;
}
.person-left-menu .edit-msg {
  	background: url('../assets/icon/edit-msg.png') center center no-repeat;
	background-size: cover;
}
.person-left-menu .chang-pwd {
  	background: url('../assets/icon/chang-pwd.png') center center no-repeat;
	background-size: cover;
}
.person-left-menu .logout {
  	background: url('../assets/icon/logout.png') center center no-repeat;
	background-size: cover;
}
.gap {
	height: 36px;
}
.brand_box {
	padding: 24px 0 28px 16px;
	font-family: Microsoft YaHei;
}
.brand_item {
  width: 272px;
  height: 426px;
  background-color: #fff;
	margin: 12px 100px 12px 0;
  float: left;
	line-height: 20px;
	border-bottom: 4px solid #939393;
	cursor: pointer;
}
.brand_item:hover {
	border-bottom: 4px solid #7dbd25;
}
.brand_item:hover .brand_operate div p,
.brand_item:hover .brand_operate i {
	color:#7dbd25;
}

.brand_item_img .brand_logo_img {
  width: 90px;
  height: 36px;
  margin: 0 26px 0 auto;
  display: block;
}
.brand_item_img .product_img {
  width: 246px;
  height: 264px;
  margin: 0 auto;
  display: block;
	border: 1px solid #dedede;
}

.brand_operate {
  display: flex;
  justify-content: space-around;
	/* justify-content: end; */
  align-items: center;
  height: 42px;
	margin-top: 4px;
  text-align: center;
}
.brand_operate div {
 cursor: pointer;
}
.brand_operate i {
	color: #939393;
	font-size: 22px;
	/* width: 24px;
	height: 24px; */
}

.textStyle{
  display: inline-block;
  width: 80px;
  text-align-last: justify;
}

.compatible {
  margin-left: 2px;
}

.notes {
  color: #939393;
  font-size: 12px;
  margin: 0 18px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.notes span {
  margin-right: 5px;
}

.brand_div_title {
	text-overflow: ellipsis;
	overflow: hidden;
  color: #939393;
  font-size: 12px;
  text-align: center;
	height: 24px;
	line-height: 24px;
  margin: 6px auto;
	white-space: nowrap;
	width: 80%;
}

.brand_operate div p {
  color: #939393;
	/* margin-top: 4px; */
  font-size: 12px;
	/* height: 16px; */
	/* line-height: 16px; */
}
.person-right {
	background-color: #fff;
	padding: 26px 20px 74px;
}
/* .person-right-info {} */
.person-right-title {
	height: 36px;
	line-height: 36px;
	font-size: 30px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #8A8A8A;
	padding: 20px 46px;
	border-bottom: 1px solid #8A8A8A;
}
.person-right-info >>> .el-button--success {
	width: 350px;
	margin: 20px auto;
	display: block;
}
.person-right-info .com-width {
	width: 700px;
	margin: 30px auto;
}
.change-type-tab {
	height: 60px;
	line-height: 60px;
}
.change-type-tab span {
	font-size: 22px;
}
.change-type-tab span.active {
	color: red;
}
.answer-width {
	margin: 0 24px;
}
.com-title {
	background-color: #e8e8e8;
	padding-left: 60px;
	height: 52px;
	line-height: 52px;
	font-size: 24px;
	color: #787878;
}
.my-answer {
	padding-bottom: 40px;
}
.my-answer li{
	display: flex;
	padding: 0 100px;
	font-size: 18px;
	align-items: center;
}
.my-answer li img{
	width: 64px;
	height: 64px;
	border-radius: 50%;
	margin-right: 20px;
}
.my-answer li > div{
	line-height: 30px;
	border-bottom: 1px solid #e8e8e8;
	padding: 30px 0 30px 30px;
	flex: 1;
}
.my-answer li div p {
	color: var(--main-color);
}
.product_discussion_content_item {
  display: flex;
  /* border: 1px solid #E8E8E8; */
  cursor: pointer;
	padding: 0 120px;
	font-size: 18px;
}
.get-code-btn >>> .el-button--success,
.my-answer >>> .el-button--success,
.my-question >>> .el-button--success {
	width: auto;
}
.content_item_left {
  width: 120px;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* border-right: 1px solid #E8E8E8; */
  margin: 10px 0;
}

.label{
  display: flex;
  align-items: center;
  color: #ffffff;
}

.label div:nth-child(1) {
  display: flex;
  background-color: #92B8C5;
  color: #FFFFFF;
  font-size: 12px;
  /* width: 60px; */
  height: 20px;
  border: 0;
  justify-content: center;
  align-items: center;
	padding: 0 20px;
}

.label div:nth-child(2) {
  border: 11px solid transparent;
  border-left: 20px solid #92B8C5;
  width: 0;
  height: 0;
}

.content_item_title {
  margin: 20px 10px;
  display: flex;
}

.content_item_bottom {
  color: #AEAEAE;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0;
}

.content_item_bottom img {
  width: 23px;
  height: 23px;
  margin-right: 10px;
  border-radius: 50%;
}

.content_item_bottom span:first-child {
  color: var(--main-color);
}

.content_item_bottom span {
  display: flex;
  align-items: center;
	margin-right: 40px;
}
.resolved {
  background-color: var(--main-color);
}

.unresolved {
  background-color: #FFA780;
}

.experience {
  background-color: #C8DBE2;
}
.product_discussion_content_status {
  color: #FFFFFF;
  font-size: 12px;
  width: 120px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
.code-item {
	padding: 0 !important;
  display: flex;
	align-items: center;
	height: 80px;
	position: relative;
	z-index: 999;
}
.code-item >>> .el-form-item {
  flex: 1;
}
.get-code-btn {
  margin-left: 10px;
  flex: 0 !important;
}
.get-code-btn >>> .el-form-item__content {
  margin-left: 0 !important;
}
.no-data {
	text-align: center;
	font-size: 26px;
	padding: 100px;
}
</style>



