<template>
  <div class="exhibition-hall-details-div">
    <div>

    </div>
    <div class="pic-div">
      <el-image class="pic-list" v-for="(imgPath,index) in picList" :key="index" :src="imgPath" :preview-src-list="[imgPath]"></el-image>
    </div>
  </div>
</template>
<script>
export default {
  name: 'exhibitionHallDetails',
  data() {
    return {
      picList: [
          "https://picsum.photos/seed/picsum/236/166",
          "https://picsum.photos/seed/picsum/236/166",
          "https://picsum.photos/seed/picsum/236/166",
          "https://picsum.photos/seed/picsum/236/166",
          "https://picsum.photos/seed/picsum/236/166",
          "https://picsum.photos/seed/picsum/236/166",
          "https://picsum.photos/seed/picsum/236/166",
          "https://picsum.photos/seed/picsum/236/166",
          "https://picsum.photos/seed/picsum/236/166",
          "https://picsum.photos/seed/picsum/236/166",
          "https://picsum.photos/seed/picsum/236/166",
          "https://picsum.photos/seed/picsum/236/166",
      ],
    }
  },
  watch: {
  },
  components: {
  },
  methods: {

  },
  mounted() {
  },
  computed: {
  },
}
</script>
<style scoped>
.pic-div {
  display: block;
  width: 100%;
  margin-top: 0px;
}
.pic-list {
  margin: 10px 15px;
  width: 238px;
  height: 166px;
  cursor: pointer;
}
</style>
