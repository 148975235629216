import request from '../request'
// 入驻
export function CompanyAdd(params) {
	return request.post({
		url: '/industry-bs/reception/company/add',
		params
	});
}

// 省
export function GetProvinceList(params) {
	return request.post({
		url: '/industry-bs/reception/districtCode/getProvinceList',
		params
	});
}

// 市
export function GetCityList(params) {
	return request.post({
		url: '/industry-bs/reception/districtCode/getCityList',
		params
	});
}

// 区
export function GetCountyList(params) {
	return request.post({
		url: '/industry-bs/reception/districtCode/getCountyList',
		params
	});
}