import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import Layout from './components/Layout.vue';
import "./style/index.css"
import { parseTime } from "@/utils/date";
import Toast from './components/public/toast'
import Bus from './bus'

Vue.use(ElementUI);
Vue.prototype.$toast = Toast;
Vue.prototype.parseTime = parseTime
Vue.prototype.$bus = Bus

new Vue({
  el: '#app',
  router,
  render: h => h(Layout)
});
