<template>
  <div class="exhibition-hall-div">
    <img :src="item" v-for="item in picList" class="pic-list" @click="openDetails(item)">
  </div>
</template>
<script>
export default {
  name: 'exhibitionHall',
  data() {
    return {
      picList: [
          'https://pic.rmb.bdstatic.com/bjh/3ea148c7ed92/240805/c30371d5a258b9f0574236e90025dc7d.jpeg',
        'https://pic.rmb.bdstatic.com/bjh/3ea148c7ed92/240805/01544533f33d4afec2028f6d23b6674f.jpeg',
        'https://pic.rmb.bdstatic.com/bjh/3ea148c7ed92/240805/8e0b29f1330cf250b4604da78e3849b1.jpeg',
        'https://pic.rmb.bdstatic.com/bjh/3ea148c7ed92/240805/817283779f4896f0d496ada5ef201c38.jpeg'],
    }
  },
  watch: {
  },
  components: {
  },
  methods: {
    openDetails(item) {
      this.$router.push({path: '/ExhibitionHallDetails', query: {imgUrl: item}})
    }
  },
  mounted() {
  },
  computed: {
  },
}
</script>
<style scoped>
.pic-list {
  width: 100%;
  /*height: auto;*/
  height: 400px;
  margin-top: 10px;
}
</style>
