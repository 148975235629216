<template>
  <div class="news-detail-container">
    <div class="news-detail-content">
      <div class="news-header">
        <h1 class="news-title">{{ newsDetail.title }}</h1>
        <div class="news-cover" v-if="newsDetail.titleImage">
          <img :src="newsDetail.titleImage" :alt="newsDetail.title">
        </div>
      </div>
      <div class="news-body">
        <iframe 
          v-if="newsDetail.content"
          :src="newsDetail.content"
          frameborder="0"
          class="wechat-iframe">
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { baseUrl } from '../../utils'
import { getNewsDetail } from '../../api/news/api'

export default {
  name: 'NewsDetail',
  data() {
    return {
      baseUrl,
      newsDetail: {
        title: '',
        content: '',
        titleImage: '',
        uuid: ''
      }
    }
  },
  methods: {
    getDetail() {
      const uuid = this.$route.query.uuid
      if (!uuid) {
        this.$router.push('/News/NewsInfo')
        return
      }

      getNewsDetail({
        uuid: uuid
      }).then(res => {
        this.newsDetail = res
        // 被微信限制，这里在当前页面直接打开
        window.location.href = this.newsDetail.content
      })
    }
  },
  mounted() {
    this.getDetail()
  }
}
</script>

<style scoped>
.news-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.news-detail-content {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.news-header {
  margin-bottom: 30px;
}

.news-title {
  font-size: 28px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  line-height: 1.4;
}

.news-cover {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  border-radius: 8px;
  margin: 20px 0;
}

.news-cover img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.news-body {
  font-size: 16px;
  line-height: 1.8;
  color: #333;
}

.news-body :deep(img) {
  max-width: 100%;
  height: auto;
  margin: 10px 0;
}

.news-body :deep(p) {
  margin: 16px 0;
}

.news-body :deep(a) {
  color: #007bff;
  text-decoration: none;
}

.news-body :deep(a):hover {
  text-decoration: underline;
}

.wechat-iframe {
  width: 100%;
  min-height: 800px;
  border: none;
  margin: 0 auto;
  display: block;
}

/* 响应式布局 */
@media (max-width: 768px) {
  .news-detail-container {
    padding: 10px;
  }

  .news-detail-content {
    padding: 15px;
  }

  .news-title {
    font-size: 22px;
  }

  .news-body {
    font-size: 15px;
  }
  
  .wechat-iframe {
    min-height: 600px;
  }
}
</style>
