<template>
    <div>
        <div class="company-details-container">
            <div class="background-image" :style="{
                backgroundImage: `url(${require('../../assets/comdis.jpg')})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }"></div>
        </div>

        <!-- 新增关于我们部分 -->
        <section class="about-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9">
                        <div class="about-title" style="text-align: left;">
                            <h3 class="title">关于我们</h3>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="about-image">
                            <img src="../../assets/test2.png" alt="公司图片">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about-content">
                            <div class="text">
                                <p v-if="companyDetailsData.description">{{ companyDetailsData.description }}</p>
                                <p v-else>每一个项目研发的成功，是我们不懈的追求。
                                    我们帮助每个B2B研发项目经理作出正确的选择。
                                    为此，我们在全球搜集并臻选优质生产商的专业产品。
                                    秉承“内容第一”的原则，我们的专业工程师团队为工程师提供9种语言的在线产品信息。
                                    协助来自不同国家不同规模的研发项目，成为我们每一天工作的动力。
                                    高质量信息缺乏，将不再成为全球研发成功的障碍。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="card-section">
          <div class="card-container">
            <div class="card" v-for="(card, index) in cards" :key="index" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" @click="cardClick(index)">
              <div class="card-image">
                <img :src="card.image" alt="Card Image">
                <div class="card-title" v-show="hoverIndex !== index">{{ card.title }}</div>
                <div class="card-description" v-show="hoverIndex === index">{{ card.description }}</div>
              </div>
            </div>
          </div>
        </section>

        <!-- 新增companyCategoryList分类展示其中分类标题是name-->
        <section id="portfolio" class="pricing" v-for="(category, index) in companyCategoryList" :key="index" v-show="category.productList">
            <div class="about-title" style="text-align: left;">
                <h3 class="title list-type-title">{{ category.name }}</h3>
            </div>
            <div class="product-carousel">
                <!-- 左右导航按钮 -->
                <button
                    class="nav-button prev"
                    @click="prevPage(index)"
                    :disabled="currentPages[index] === 0"
                    :class="{ 'disabled': currentPages[index] === 0 }">
                    <span class="arrow-left"></span>
                </button>

                <button
                    class="nav-button next"
                    @click="nextPage(index)"
                    :disabled="!hasNextPage(category.productList, index)"
                    :class="{ 'disabled': !hasNextPage(category.productList, index) }">
                    <span class="arrow-right"></span>
                </button>

                <!-- 产品网格 -->
                <div class="product-grid-container">
                    <div class="product-grid">
                        <div class="product-list">
                            <div v-for="item in getPaginatedProducts(category.productList, index)"
                                 :key="item.uuid"
                                 class="product-card"
                                 @click="PDFHandleClick(item)">
                                <div class="product-image">
                                    <img v-if="item.brandImage" class="brand-logo" :src="baseUrl + item.brandImage" alt="brand logo">
                                    <img v-if="item.imageUrl" class="main-image"
                                         :src="item.imageUrl ? (baseUrl + item.imageUrl.split(',')[0]) : ''"
                                         :alt="item.name">
                                </div>
                                <div class="product-info">
                                    <h3 class="product-name" :title="item.name">{{ item.name }}</h3>
                                    <div class="product-category">{{ item.subCatIdName }}</div>
                                    <div class="product-subcategory">
                                        <span>{{ item.thirdCatIdName }}</span>
                                        <span v-if="item.fourCatIdName">{{ item.fourCatIdName }}</span>
                                    </div>
                                    <div class="product-stats">
                                        <div class="stat-item">
                                            <i class="el-icon-view"></i>
                                            <span>{{ item.browseCount }}</span>
                                        </div>
                                        <div class="stat-item">
                                            <i class="el-icon-download"></i>
                                            <span>{{ item.downloadCount }}</span>
                                        </div>
                                        <div class="stat-item">
                                            <i class="el-icon-star-on"></i>
                                            <span>{{ item.collectCount }}</span>
                                        </div>
                                        <div class="stat-item">
                                            <i class="el-icon-share"></i>
                                            <span>{{ item.shareCount }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { baseUrl } from '../../utils';
import { GetCompanyDetails,GetCompanyInfo,GetPDFList } from '../../api/pdf/api'
import { GetCategoryList } from '../../api/home/api'
import menuList from '../public/menu.vue'
import goodsSearch from '../public/goodsSearch.vue'
import companyItemList from '../public/companyItemList.vue'
import paging from '../public/paging.vue'
import goodsList from '../public/goodsList.vue'
import Bus from '../../bus'
export default {
    name: 'CompanyDetails',
    data() {
        return {
      hoverIndex: -1,
      cards: [
        {
          image: require('../../assets/cp2.png'),
          title: '产品目录',
          description: '展示当前品牌中的所有产品信息，方便用户快速查找所需的产品。',
        },
        {
          image: require('../../assets/xp2.png'),
          title: '产品照片',
          description: '展示当前品牌中的所有产品照片，方便用户快速查找所需的产品。',
        },
        {
          image: require('../../assets/pdf2.png'),
          title: 'PPT/视频',
          description: '展示当前品牌中的所有PPT和视频，方便用户快速查找所需的产品。',
        },
      ],
            searchList: {

            },
            fieldType: {
                brandType: '品牌',
                productType: '产品',
                industryType: '工业',
                functionType: '功能'
            },
            baseUrl,
            // 是否展示菜单
            menuShow: true,
            // 菜单列表
            menuDataList: [],
            // 产品总条数
            total: 0,
            // 产品列表
            pdfList: [],
            // 当前页数
            currentPage: 1,
            // 每页条数
            pageSize: 4,
            // brand 品牌筛选
            brandId: 0,
            // 三级条件
            thirdCatId: 0,
            fourCatId: 0,
            // 产品分类
            productType: 0,
            subCatId: 0,
            // 产业分类
            industryType: 0,
            // 搜索文字
            key: '',
            functionId: 0,
            // 筛选条件
            queryData: [],
            // 是否有搜索条件
            searching: false,
            // 条件列表
            tagList: [],
            itemId: 0,
            itemName: 0,
            industryFirstCatId: 0,
            companyId: '',
            companyDetailsData: {},
            companyCategoryList: [],
            industrySecordCatId: '',
            industrySecordCatName: '',
            currentPages: {}, // 存储每个分类的当前页码
        }
    },
    watch: {
        searching: {
            handler(val) {}
        },
        '$route.query.companyId'(val){
            console.log("详情页面")
            this.init();
        },
    },
    components: {
        menuList,
        goodsSearch,
        companyItemList,
        paging,
        goodsList
    },
    methods: {
        init() {
            let { companyId,industrySecordCatId,industrySecordCatName } = this.$route.query;
            this.companyId = companyId;
            this.industrySecordCatId = industrySecordCatId;
            this.industrySecordCatName = industrySecordCatName;
            this.getDetails();
        },
        getTagName(item) {
            return this.fieldType[item.fieldType];
        },
        initValue(type = 'all', fieldName) {
            this.currentPage = 1;
            if(type === 'all') {
                this.brandId = 0;
                this.functionId = 0;
                this.subCatId = 0;
                this.thirdCatId = 0;
                this.fourCatId = 0;
                this.industryFirstCatId = 0;
                this.industrySecordCatId = 0;
                // this.key = '';
            } else if(type === 'brandType') {
                this.brandId = 0;
            } else if(type === 'productType') {
                if(fieldName === 'subCatId') {
                    this.subCatId = 0;
                    this.thirdCatId = 0;
                } else if(fieldName === 'thirdCatId') {
                    this.thirdCatId = 0;
                }
                this.fourCatId = 0;
            } else if(type === 'industryType') {
                if(fieldName === 'industryFirstCatId') {
                    this.industryFirstCatId = 0;
                }
                this.industrySecordCatId = 0;
            } else if(type === 'fucntionType') {
                this.functionId = 0;
            }

        },
        deleteSearchText() {
            this.key = '';
            window.localStorage &&  window.localStorage.setItem('searchKey', '');
            window.selfEventSm && window.selfEventSm.clearSearchKey && window.selfEventSm.clearSearchKey()
            this.getPDFList();
        },
        deleteTagList(item) {
            let tagList = this.tagList.filter(tag => {
                let {fieldType: type, fieldName, level, key} = item;
                if(type === 'brandType') {
                    return tag.fieldType !== type;
                    // return tag.level !== level;
                } else if(type === 'functionType') {
                    this.functionId = 0;
                    return tag.fieldType !== type;
                } else if(type === 'productType') {
                    if(fieldName === 'subCatId' || key === 'subCatId') {
                        return tag.fieldType !== type;
                    } else if(fieldName === 'thirdCatId' || key === 'thirdCatId') {
                        return tag.level !== level && tag.level !== 4;
                    }
                    return tag.level !== level;
                } else if(type === 'industryType') {
                    if(fieldName === 'industryFirstCatId') {
                        return tag.fieldType !== type;
                    }
                    return tag.level !== level;
                }
            });
            this.tagList = [...tagList];
        },
        deleteTag(item) {
            this.deleteTagList(item);
            this.initValue(item.fieldType, item.fieldName || item.key);
            this.tagList.forEach(item => this[item.key] = item.value);
            this.getPDFList();
        },
        handleCurrentChange(value) {
            this.currentPage = value;
            this.getPDFList();
        },
        menuSwitch(b) {
            this.menuShow = b;
            // if(b) {
            //   this.pageSize = 20;
            // } else {
            //   this.pageSize = 24;
            // }
            this.pageSize = 24;
            this.currentPage = 1;
            this.getPDFList();
        },
        handleMenuItemClick(item) {
            this.tagList = [];
            let obj = {
                level: 1,
                key: 'thirdCatId',
                name: item.name,
                value: item.id,
                fieldType: 'productType'
            }
            this.initValue(obj.fieldType, obj.key);
            this.pushTagList(obj);
            this.thirdCatId = item.id;
            this.getPDFList();
        },
        getCategoryList() {
            GetCategoryList().then(data => {
                data.forEach((item, index) => {
                    item.index = index + '';
                    if(item.categroyList && item.categroyList.length) {
                        item.categroyList.forEach((childItem, childIndex) => {
                            childItem.index = index + '-' + childIndex;
                            if(childItem.categroyList && item.categroyList.length) {
                                childItem.categroyList.forEach((childSubItem, childSubIndex) => {
                                    childSubItem.index = index + '-' + childIndex + '-' + childSubIndex;
                                });
                            }
                        });
                    }
                });
                this.menuDataList = [...data];
            })
        },
        getDetails() {
            let that = this;
            GetCompanyInfo({
                'companyId': this.companyId
            }).then(data => {
                this.companyDetailsData = [...data];
            })

            // 获取分类
            GetCompanyDetails({
                'companyId': this.companyId
            }).then(data => {
                if (this.industrySecordCatId && this.industrySecordCatName) {
                    that.companyCategoryList = [{
                        name: this.industrySecordCatName,
                        value: this.industrySecordCatId,
                        productList: []
                    }];
                } else {
                    that.companyCategoryList = data.data;
                }

                // 初始化分页
                that.companyCategoryList.forEach((item, index) => {
                    that.$set(that.currentPages, index, 0);
                    that.getPDFListByData(data.name, item);
                });
            })
        },
        pushTagList(item) {
            let tagList = [...this.tagList];
            tagList = tagList.filter(tag => (tag.level !== item.level));
            tagList.push(item);
            this[item.fieldName] = item.value;
            this.$nextTick(() => {
                tagList.sort((a, b) => {
                    return a.level - b.level;
                });
                this.tagList = [...tagList];
            });
        },
        brandItemHandleClick(item) {
            item.key = 'brandId';
            item.level = 1;
            this.pushTagList(item);
            this.getPDFList();
        },
        functionItemHandleClick(item) {
            item.key = 'functionId';
            item.level = 10;
            this.pushTagList(item);
            this.getPDFList();
        },
        handleClickProductItem(item) {
            item.key = 'productType';
            item.level = 2;
            if(item.fieldName === 'subCatId') {
                item.level = 2;
            } else if(item.fieldName === 'thirdCatId') {
                item.level = 3;
            } else if(item.fieldName === 'fourCatId') {
                item.level = 4;
            }
            this.pushTagList(item);
            this.getPDFList();
        },
        PDFHandleClick(item) {
            // 打开品牌详情
            window.open(window.location.origin + '#/Pdf/PdfDetail?uuid=' + item.uuid)
        },
        handleClickIndustryItem(item) {
            item.key = 'industryType';
            item.level = 5;
            if(item.fieldName === 'industryFirstCatId') {
                item.level = 5;
            } else if(item.fieldName === 'industrySecordCatId') {
                item.level = 6;
            }
            this.pushTagList(item);
            this.getPDFList();
        },
        getPDFListByData(name, data) {
            let that = this;
            GetPDFList({
                'companyId': this.companyId,
                [name]: data.value,
                pageNum: 1,
                pageSize: 50
            }).then(data1 => {
                if (data1.list && data1.list.length) {
                    that.$set(data, 'productList', data1.list);
                }
            })
        },
        // 获取当前页的产品数据
        getPaginatedProducts(products, categoryIndex) {
            if (!products) return [];
            if (typeof this.currentPages[categoryIndex] === 'undefined') {
                this.$set(this.currentPages, categoryIndex, 0);
            }
            const start = this.currentPages[categoryIndex] * this.pageSize;
            return products.slice(start, start + this.pageSize);
        },

        // 检查是否有下一页
        hasNextPage(products, categoryIndex) {
            if (!products) return false;
            const totalPages = Math.ceil(products.length / this.pageSize);
            return this.currentPages[categoryIndex] < totalPages - 1;
        },

        // 上一页
        prevPage(categoryIndex) {
            if (this.currentPages[categoryIndex] > 0) {
                this.$set(this.currentPages, categoryIndex, this.currentPages[categoryIndex] - 1);
            }
        },

        // 下一页
        nextPage(categoryIndex) {
            const products = this.companyCategoryList[categoryIndex].productList;
            if (products && this.hasNextPage(products, categoryIndex)) {
                this.$set(this.currentPages, categoryIndex, this.currentPages[categoryIndex] + 1);
            }
        },
        cardClick(index) {
            let query =  {
                companyId: this.companyId,
            };
            if (index == 0) {
                this.$router.push({
                    path: '/Pdf',
                    query
                });
            }
            else if (index == 1) {
                this.$router.push({
                    path: '/ProductPic',
                    query
                });
            }
            else if (index == 2) {
                this.$router.push({
                    path: '/ProductVideo',
                    query
                });
            }
        }
    },
    mounted() {
        this.init();
    }
}
</script>
<style scoped>
.card-section {
  padding: 80px 0;
}

.card-container {
  display: flex;
  justify-content: space-between;
  max-width: 1500px;
  margin: 0 auto;
  gap: 40px;
}

.card {
  width: 32%;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0,0,0,0.12);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card:hover {
  transform: scale(1.06);
  box-shadow: 0 12px 32px rgba(0,0,0,0.2);
}

.card-image {
  position: relative;
  width: 100%;
  height: 360px;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.4s ease;
}

.card:hover .card-image img {
  transform: scale(1.12);
}

.card-title,
.card-description {
  position: absolute;
  left: 24px;
  bottom: 24px;
  color: white;
  font-weight: 600;
  font-size: 24px;
  text-shadow: 0 2px 8px rgba(0,0,0,0.6);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.card-description {
  opacity: 0;
  transform: translateY(24px);
  font-size: 18px;
  line-height: 1.5;
}

.card:hover .card-description {
  opacity: 1;
  transform: translateY(0);
}

.card:hover .card-title {
  opacity: 0;
  transform: translateY(-24px);
}
@import '../../style/pdf.css';
:root {
    --background-color: #ffffff;
    --background-color-rgb: 255, 255, 255;
    --default-color: #212529;
    --default-color-rgb: 33, 37, 41;
    --primary-color: #e84545;
    --primary-color-rgb: 232, 69, 69;
    --secondary-color: #32353a;
    --secondary-color-rgb: 50, 53, 58;
    --contrast-color: #ffffff;
    --contrast-color-rgb: 255, 255, 255;
}
/* 原有样式保持不变 */
.company-details-container {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
}

/* 关于我们部分优化后的样式 */
.about-area {
    padding: 80px 0;
    background-color: #fff;
}

.category-section {
    padding: 60px 0;
    background-color: #f8f8f8;
}

.container {
    /* 已有样式保持不变 */
}

.category-list {
    display: block; /* 改为块级布局 */
}

.category-item {
    width: 100%;
    margin-bottom: 30px;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
}

.category-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.container {
    width: 100%;
    max-width: 1500px;  /* 限制最大宽度 */
    margin: 0 auto;
    padding: 0 30px;    /* 增加两侧内边距 */
}

/* 调整行间距 */
.row.align-items-center {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    margin: 0 -15px;
    gap: 30px;          /* 增加图片和文字间距 */
}

.col-lg-6 {
    flex: 0 0 calc(50% - 15px); /* 减去间距的一半 */
    max-width: calc(50% - 15px);
    padding: 0 15px;
    box-sizing: border-box;
}

.about-title .title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 40px;
    color: #333;
    text-align: left;  /* 改为左对齐 */
    position: relative;
}

.about-title .title::after {
    content: '';
    position: absolute;
    left: 0;  /* 改为从左侧开始 */
    bottom: -10px;
    transform: none;  /* 移除居中变换 */
    width: 80px;
    height: 3px;
    /* background: #1890ff; */
}

/* 确保左右布局的关键样式 */
.row.align-items-center {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    margin: 0 -15px;
}

.col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
    box-sizing: border-box;
}

.about-image {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 8px 20px rgba(0,0,0,0.1);
    height: 100%;
}

.about-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: all 0.3s;
}

.about-content {
    padding-left: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 30px;
}

.about-content .text {
    line-height: 1.8;
    color: #666;
    font-size: 20px;
    text-align: justify;
    text-indent: 2em;
}

.about-content .text p {
    margin-bottom: 1em;
}
/* Pricing Section - Home Page
------------------------------*/

.pricing {
    max-width: 1650px;  /* 限制最大宽度 */
    margin: 0 auto;
}

.pricing .section-title {
    /*margin-bottom: 40px;*/
}

.pricing .pricing-item {
    box-shadow: 0 3px 20px -2px rgba(var(--default-color-rgb), 0.1);
    padding: 60px 40px;
    height: 100%;
    position: relative;
    border-radius: 15px;
}

.pricing h3 {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 20px;
    text-align: center;
}

.pricing .icon {
    margin: 30px auto 20px auto;
    width: 70px;
    height: 70px;
    background: var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    transform-style: preserve-3d;
}

.pricing .icon i {
    color: var(--background-color);
    font-size: 28px;
    transition: ease-in-out 0.3s;
    line-height: 0;
}

.pricing .icon::before {
    position: absolute;
    content: "";
    height: 86px;
    width: 86px;
    border-radius: 50%;
    background: rgba(var(--primary-color-rgb), 0.2);
    transition: all 0.3s ease-out 0s;
    transform: translateZ(-1px);
}

.pricing .icon::after {
    position: absolute;
    content: "";
    height: 102px;
    width: 102px;
    border-radius: 50%;
    background: rgba(var(--primary-color-rgb), 0.1);
    transition: all 0.3s ease-out 0s;
    transform: translateZ(-2px);
}

.pricing h4 {
    font-size: 48px;
    color: var(--primary-color);
    font-weight: 700;
    font-family: var(--heading-font);
    margin-bottom: 25px;
    text-align: center;
}

.pricing h4 sup {
    font-size: 28px;
}

.pricing h4 span {
    color: rgba(var(--default-color-rgb), 0.5);
    font-size: 18px;
    font-weight: 400;
}

.pricing ul {
    padding: 20px 0;
    list-style: none;
    color: rgba(var(--default-color-rgb), 0.8);
    text-align: left;
    line-height: 20px;
}

.pricing ul li {
    padding: 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pricing ul span {
    /* color: #059652; */
    font-size: 14px;
    padding-right: 3px;
}

.pricing ul li {
    padding: 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pricing ul li img {
    width: 18px;
    height: 18px;
}

.pricing ul .na {
    color: rgba(var(--default-color-rgb), 0.3);
}

.pricing ul .na i {
    color: rgba(var(--default-color-rgb), 0.3);
}

.pricing ul .na span {
    text-decoration: line-through;
}

.pricing .buy-btn {
    color: rgba(var(--default-color-rgb), 0.8);
    background-color: var(--contrast-color);
    display: inline-block;
    padding: 10px 40px;
    border-radius: 4px;
    border: 1px solid rgba(var(--default-color-rgb), 0.2);
    transition: none;
    font-size: 16px;
    font-weight: 600;
    font-family: var(--heading-font);
    transition: 0.3s;
}

.pricing .buy-btn:hover {
    background-color: var(--primary-color);
    color: var(--contrast-color);
}

.pricing .featured {
    z-index: 10;
    border: 3px solid var(--primary-color);
}

.pricing .featured .buy-btn {
    background-color: var(--primary-color);
    color: var(--contrast-color);
}

.pricing .featured .buy-btn:hover {
    background-color: rgba(var(--primary-color-rgb), 0.9);
}

@media (min-width: 992px) {
    .pricing .featured {
        transform: scale(1.15);
    }
}


/* Faq Section - Home Page
------------------------------*/

.groups {
    display: flex;
}

.owl-nav {
    margin-top: -348px;
    padding-bottom: 249px;
    justify-content: space-between;
    display: flex;
}

.owl-nav button.owl-prev{
    position: relative;
    /*left:-50px;*/
    /*top: 50%;*/
    margin-top: -16px;
    width: 45px;
    height: 33px;
    font-size: 0;
    z-index: 50;
    border: none;
}

.owl-nav button.owl-next{
    position: relative;
    /*right:-50px;*/
    /*top: 50%;*/
    margin-top: -25px;
    width: 45px;
    height: 33px;
    font-size: 0;
    z-index: 50;
    border: none;
}

.owl-nav button.owl-prev span{
    position: absolute;
    width: 20px;
    height: 33px;
    background-repeat: no-repeat;
    background-position: center;
    content:"";
    left: 50%;
    margin-left: -10px;
    top: 0;
    background-size: 100%;
    background-image: url(../../assets/yyyy1.png);
    opacity: 0.6;
}

.owl-nav button.owl-next span{
    position: absolute;
    width: 20px;
    height: 33px;
    background-repeat: no-repeat;
    background-position: center;
    content:"";
    left: 50%;
    margin-left: -10px;
    top: 0;
    background-size: 100%;
    background-image: url(../../assets/yyyy2.png);
    opacity: 0.6

}

.owl-nav button::before{
    position: absolute;
    width: 20px;
    height: 33px;
    background-repeat: no-repeat;
    background-position: center;
    content:"";
    left: 0;
    top: 0;
    background-size: 100%;
    animation-iteration-count: infinite;
    animation-timing-function:linear;
}



.owl-nav button::after{
    position: absolute;
    width: 20px;
    height: 33px;
    background-repeat: no-repeat;
    background-position: center;
    content:"";
    right: 0;
    top: 0;
    background-size: 100%;
    animation-iteration-count: infinite;
    animation-timing-function:linear;
}

.owl-nav button.owl-prev::before{
    background-image: url(../../assets/yyyy1.png);
    opacity: 0.3

}
.owl-nav button.owl-prev::after{
    background-image: url(../../assets/yyyy1.png);
    opacity: 0.9;
}

.owl-nav button.owl-next::before{
    background-image: url(../../assets/yyyy2.png);
    opacity: 0.9;

}
.owl-nav button.owl-next::after{
    background-image: url(../../assets/yyyy2.png);
    opacity: 0.3
}


.owl-nav button.owl-prev{
    animation: jtanONE 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count:infinite;
}


.owl-nav button.owl-next{
    animation: jtanTWO 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count:infinite;
}
.owl-nav button.disabled{
    display: none!important;
}

@keyframes jtanONE
{
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(-15px);
    }
    100% {
        transform: translateX(0px);
    }
}

@-webkit-keyframes jtanONE
{
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(-15px);
    }
    100% {
        transform: translateX(0px);
    }
}



@keyframes jtanTWO
{
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(15px);
    }
    100% {
        transform: translateX(0px);
    }
}

@-webkit-keyframes jtanTWO
{
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(15px);
    }
    100% {
        transform: translateX(0px);
    }
}

.product-carousel {
    position: relative;
    padding: 0 60px;
    margin: 30px 0;
}

.product-grid-container {
    overflow: hidden;
}

.product-grid {
    transition: transform 0.3s ease;
}

.product-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    padding: 20px 0;
    min-height: 450px; /* 防止切换时的页面抖动 */
}

.product-card {
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    transition: all 0.3s ease;
    cursor: pointer;
    border-bottom: 4px solid #939393;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.product-card:hover {
    transform: translateY(-5px);
    border-bottom-color: var(--primary-color);
}

.product-card.fade-enter,
.product-card.fade-leave-to {
    opacity: 0;
}

.product-image {
    position: relative;
    padding: 15px;
    background: #fff;
}

.brand-logo {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 90px;
    height: 36px;
    object-fit: contain;
}

.main-image {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 8px;
    border: 1px solid #eee;
}

.product-info {
    padding: 20px;
}

.product-name {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product-category,
.product-subcategory {
    color: #666;
    font-size: 14px;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product-subcategory span {
    margin-right: 10px;
}

.product-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #eee;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #939393;
}

.stat-item i {
    font-size: 20px;
    margin-bottom: 5px;
}

.stat-item span {
    font-size: 12px;
}

.product-card:hover .stat-item {
    color: var(--primary-color);
}

/* 导航按钮样式 */
.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border: none;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    cursor: pointer;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.nav-button:hover {
    background: var(--primary-color);
}

.nav-button:hover .arrow-left,
.nav-button:hover .arrow-right {
    border-color: #fff;
}

.nav-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.nav-button.prev {
    left: 0;
}

.nav-button.next {
    right: 0;
}

.arrow-left,
.arrow-right {
    border: solid #333;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 6px;
    transition: border-color 0.3s ease;
}

.arrow-left {
    transform: rotate(135deg);
}

.arrow-right {
    transform: rotate(-45deg);
}

/* 响应式布局 */
@media (max-width: 1400px) {
    .product-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 1100px) {
    .product-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .product-list {
        grid-template-columns: repeat(1, 1fr);
    }

    .product-carousel {
        padding: 0 40px;
    }
}
.list-type-title {
  padding: 0 80px;
}
</style>
