<template>
	<div :class="subMenuShow ? 'left_menu menu_open' : 'left_menu menu_close'" ref="left_menu_ref">
		<div class="menu_div">
			<div v-for="(item, index) in menuDataList" :key="index" :style="{paddingTop: '2px'}">
				<div class="left_menu_title">{{item.name}}</div>
				<el-menu
					default-active="2"
					class="el-menu-vertical-demo"
					@open="handleOpen"
					@close="handleClose"
					active-text-color="#7dbd26"
					unique-opened
					>
					<el-submenu 
						v-for="(childItem, childIndex) in item.categroyList"
						:key="childIndex"
						:index="childItem.index"
					>
						<template slot="title">
							<!-- <span class="menu_icon">|</span> -->
							<span class="menu-item-span">{{childItem.name}}</span>
						</template>
						<el-menu-item
							v-for="(childSubItem, childSubIndex) in childItem.categroyList"
							:key="childSubIndex"
							:index="childSubItem.index"
							@click="handleMenuItemClick(childSubItem)"
						>{{childSubItem.name}}</el-menu-item>
					</el-submenu>
				</el-menu>
			</div>
		</div>
		<div class="menu_switch">
			<div @click="menuSwitch">
				<i v-if="subMenuShow" class="el-icon-caret-left"></i>
				<i v-else class="el-icon-caret-right"></i>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'menuList',
	props: {
		menuShow: {
			type: Boolean,
			default: true,
		},
		menuDataList: {
			type: Array,
			default: []
		}
	},
	data() {
		return {
			subMenuShow: this.menuShow
		}
	},
	mounted() {},
	methods: {
		handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
		menuSwitch() {
      this.subMenuShow = !this.subMenuShow;
			this.$emit('menuSwitch', this.subMenuShow);
    },
		handleMenuItemClick(item) {
			this.$emit('handleMenuItemClick', item);
		}
	}
}
</script>

<style scoped>
.left_menu {
  width: 416px;
  display: flex;
  /*align-items: center;*/
}

.menu_div {
  width: 378px;
	padding: 0 2px 50px 0;
	box-sizing: border-box;
  background-color: #fff;
	font-family: Microsoft YaHei;
}
.menu_div >>> .el-menu-item {
	font-size: 17px !important;
}
.menu_switch {
  width: 28px;
  text-align: center;
  line-height: 600px;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.menu_switch div:first-child {
	width: 100%;
	height: 314px;
	background-color: #fff;
	border-radius: 0 25px 25px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #cdcdcd;
}

.left_menu_title {
  color: #fff;
  background-color: var(--main-color);
  font-size: 17px;
	height: 42px;
	line-height: 42px;
	/* margin-top: 4px; */
  padding-left: 30px;
}

.left_menu >>>.el-submenu__title {
	font-size: 17px;
	height: 42px;
	line-height: 42px;
  padding-left: 42px !important;
	color: #8a8a8a !important;
	background-color: #ededed;
}

.menu-item-span {
	padding-left: 30px;
	position: relative;
}
.menu-item-span::before {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	top: 50%;
	height: 14px;
	width: 2px;
	background-color: var(--main-color);
	border-radius: 2px;
	transform: translateY(-50%);
}

.menu_icon {
  color: var(--main-color);
  margin-right: 10px;
}

.menu_close {
  margin-left: -380px;
  transition: margin-left 0.5s;
}

.menu_open {
  margin-left: 0;
  transition: margin-left 0.5s;
}

.el-menu-item.is-active {
  background-color: #fff !important;
  border-bottom: 1px solid #eee !important;
}

.el-menu-item {
  border: 2px solid #eee !important;
	font-size: 17px;
	height: 42px;
	line-height: 42px;
  padding-left: 60px !important;
	color: #8a8a8a !important;
	border-bottom: 1px solid #eee !important;
	border-top: 1px solid #eee !important;
	text-overflow: ellipsis;
	overflow: hidden;
}
.el-menu-vertical-demo > li {
	border-bottom: 2px solid #fff;
}
</style>