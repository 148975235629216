import request from '../request'

export function GetCategoryList() {
  return request.post({
    url: '/industry-bs/reception/firstPage/categoryList',
  });
}

export function GetCategoryListOther() {
  return request.post({
    url: '/industry-bs/reception/firstPage/categoryListOther',
  });
}

export function GetBannerList(params) {
  return request.post({
    url: 'industry-bs/reception/firstPage/getBannerList',
    // params: { ...params, showMask: true }
  });
}

export function GetRoomList() {
  return request.post({
    url: '/industry-bs/reception/firstPage/showRoomList',
  });
}