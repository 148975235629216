<template>
  <div class="product-detail">
    <div class="product-detail-bar">
      <img v-if="canvasDetail && canvasDetail.brandImage" :src="baseUrl + canvasDetail.brandImage" />
      <div class="product-detail-bar-right">
        <div>
          <i class="el-icon-view"></i>
          <span>{{canvasDetail.browseCount}}</span>
        </div>
        <div @click="download">
          <i class="el-icon-download"></i>
          <span>{{canvasDetail.downloadCount}}</span>
        </div>
        <div @click="collect">
          <i :class="[isCollected ? 'el-icon-star-on' : 'el-icon-star-off']"></i>
          <span>{{canvasDetail.collectCount}}</span>
        </div>
        <div @click.stop="share">
          <i class="el-icon-share"></i>
          <span>{{canvasDetail.shareCount}}</span>
        </div>
        <div :class="['share_tools', showShareCode ? 'show' : '']"></div>
      </div>
    </div>
    <div class="product-detail-content">
      <div class="product-detail-content-left">
        <div v-if="sliders.length" class="slider-info">
          <el-carousel height="280" indicator-position="none">
            <el-carousel-item v-for="(item) in sliders" :key="item.imageUrl">
              <!-- <img :src="baseUrl + item.imageUrl" class="slider_img" @click="turnToTarget(item)"> -->
              <el-image
                class="slider_img"
                :src="item.imageUrl" 
                :preview-src-list="srcList">
              </el-image>
            </el-carousel-item>
          </el-carousel>
          <div class="slider-info-list">
            <!-- <img v-for="(item, index) in sliders" :key="index" :src="baseUrl + item.imageUrl"/> -->
            <el-image
              v-for="(item) in sliders"
              :key="item.imageUrl"
              :src="item.imageUrl" 
              :preview-src-list="srcList">
            </el-image>
          </div>
        </div>
      </div>
      <div class="product-detail-content-right">
        <p class="common-title">图纸属性</p>
        <div class="product-detail-fields">
          <p v-if="canvasDetail.name">名   称: {{canvasDetail.name}}</p>
          <p v-if="canvasDetail.companyName">供应商: {{canvasDetail.companyName}}</p>
          <p v-if="canvasDetail.descr">产品介绍: {{canvasDetail.descr}}</p>
          <p v-if="canvasDetail.firstCatIdName">功能名称: {{canvasDetail.firstCatIdName}}</p>
          <p v-if="canvasDetail.industryFirstCatName">行业名称: {{canvasDetail.industryFirstCatName}}</p>
        </div>
        <p class="common-title">图纸包含文件</p>
        <div class="product-detail-files">
          <p v-if="currentPath">{{currentPath}}</p>
          <ul v-if="openLevel !== 0">
            <li @click="prevLevel">
              <span>
                <img :src="src1" />
                上一级
              </span>
            </li>
          </ul>
          <ul>
            <li v-for="(item, index) in fileList" :key="index" @click="handleItemClick(item, index)">
              <span v-if="openLevel === item.level">
                <img :src="item.text.indexOf('.') !== -1 ? src2 : src1" />
                {{item.text}}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="product-recommoned">
      <p class="common-title">图纸推荐</p>
      <div class="product-recommoned-info">
        <div class="brand_box clearfix">
          <div v-for="(item, index) in recommendLists" :key="index" @click="toDetail(item)" class="brand_item">
            <div class="brand_item_img">
              <img v-if="item.brandImage" class="brand_logo_img" :src="baseUrl + item.brandImage">
              <div v-else class="gap"></div>
              <img class="product_img" :src="item.imageUrl ? (baseUrl + item.imageUrl.split(',')[0]) : ''">
            </div>
            <div class="brand_div_title" :title="item.name">{{item.name}}</div>
            <div class="notes">{{item.subCatIdName}}</div>
            <div class="notes">
              <span>{{item.thirdCatIdName}}</span>
              <span>{{item.fourCatIdName}}</span>
            </div>
            <div class="brand_operate">
              <div>
                <i class="el-icon-view"></i>
                <p>{{item.browseCount}}</p>
              </div>
              <div>
                <i class="el-icon-download"></i>
                <p>{{item.downloadCount}}</p>
              </div>
              <div>
                <i class="el-icon-star-on"></i>
                <p>{{item.collectCount}}</p>
              </div>
              <div>
                <i class="el-icon-share"></i>
                <p>{{item.shareCount}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-image 
      style="width: 100px; height: 100px"
      :src="url" 
      :preview-src-list="srcList">
    </el-image>
    <el-dialog
      title=""
      :visible.sync="showLogin"
      width="40%">
      <login-info @loginCb="loginCb"></login-info>
    </el-dialog>
  </div>
</template>
<script>
import { baseUrl } from '../../utils';
import LoginInfo from '../public/loginInfo.vue'
// import { GetBannerList  } from '../../api/home/api'
import src1 from '../../assets/other/dir.png';
import src2 from '../../assets/other/file.png';
import Bus from '../../bus'
import { GetCanvasDetail, RecommendList, PostCancelCollect, GetCanvasDetailIsCollect, PostCanvasBrowseNoAuth, PostCanvasBrowse, PostDownload, PostCollect, PostCanvasShareNoAuth, PostCanvasShare } from '../../api/canvas/api'
export default {
  name: 'CanvasDetail',
  data() {
    return {
      showShareCode: false,
      src1,
      src2,
      sliders: [],
      loginCbByField: '',
			baseUrl,
      uuid: '',
      showLogin: false,
      isCollected: false,
      canvasDetail: {},
      recommendLists: [],
      fileList: [],
      loading: null,
      url: '',
      srcList: [],
      // pdfPage: 1, // pdf 页码
      numPages: 1, // pdf 总页数
      openLevel: 0,
      levelLen: 0,
      currentPath: ''
    }
  },
  components: {
    LoginInfo
  },
  methods: {
    getCurrentPath(data) {
      // let path = []
      // data.forEach((item, index) => {
      //   console.log(item, index, this.openLevel, 'openLevelopenLevel')
      //   if(index === this.openLevel) {
      //     path.push(item.text);
      //   }
      // });
      // console.log(path, 'path')
      // this.currentPath = path.join('/');
    },
    prevLevel() {
      if(this.openLevel > 0) {
        this.openLevel = this.openLevel - 1;
        this.getCurrentPath(this.fileList);
      }
    },
    handleItemClick() {
      if(this.openLevel < this.levelLen - 1) {
        this.openLevel = this.openLevel + 1;
        this.getCurrentPath(this.fileList);
      }
    },
    getBanner(urls) {
      let imgArr = urls.split(',');
      this.sliders = imgArr.map(item => {
        return {
          imageUrl: this.baseUrl + item
        }
      })
      this.srcList = imgArr.map(item => {
        return this.baseUrl + item
      })
    },
    loginCb(data) {
      // if(data) {
      //   window.localStorage && window.localStorage.setItem('token', data)
      //   // 获取用户接口放置首页处理
      //   this.getUserInfo(data);
      // }
      if(this.loginCbByField === 'download') {
        this.PostDownloadCb();
      } else if(this.loginCbByField === 'collect') {
        // this.PostCollectCb();
      }
      this.getCanvasDetailIsCollect()
      this.showLogin = false;
      // this.$nextTick(() => {
      //   Bus.$emit('showLogin', false);
      // })
    },
    getShareCode() {
      const codeFigure = new AraleQRCode({
        render: 'svg',  // 生成的类型 'svg' or 'table'
        text: location.href, // 需要生成二维码的链接
        size: 120 // 生成二维码大小
      });
      const share_tools = document.querySelector('.share_tools');
      share_tools.appendChild(codeFigure);
    },
    share() {
      let isLogin = this.isLogin();
      this.showShareCode = true;
      if(isLogin) {
        PostCanvasShare({
          uuid: this.uuid
        }).then(data => {
          this.canvasDetail = {
            ...this.canvasDetail,
            shareCount: this.canvasDetail.shareCount + 1
          }
          console.log('已登陆 记录分享次数 待补充qq 微信分享逻辑')
        });
      } else {
        PostCanvasShareNoAuth({
          uuid: this.uuid
        }).then(data => {
          this.canvasDetail = {
            ...this.canvasDetail,
            shareCount: this.canvasDetail.shareCount + 1
          }
          console.log('未未未未未未登陆 记录分享次数 待补充qq 微信分享逻辑')
        });
      }
    },
    isLogin() {
      return !!(window.localStorage && window.localStorage.getItem('token'))
    },
    PostDownloadCb() {
      PostDownload({
        uuid: this.uuid
      }).then(data => {
        console.log('下载data', data);
        this.canvasDetail = {
          ...this.canvasDetail,
          downloadCount: this.canvasDetail.downloadCount + 1
        }
      });
    },
    download() {
      let isLogin = this.isLogin();
      if(!isLogin) {
        // this.$router.push('/Login');
        this.showLogin = true;
        this.loginCbByField = 'download';
        return;
      }
      this.PostDownloadCb();
    },
    PostCollectCb(){
      // 判断是否已收藏
      if(this.isCollected) {
        // 将取消收藏
        PostCancelCollect({
          uuid: this.uuid
        }).then(data => {
          this.isCollected = false;
          this.canvasDetail = {
            ...this.canvasDetail,
            collectCount: this.canvasDetail.collectCount - 1
          }
        });
      } else {
        // 收藏
        PostCollect({
          uuid: this.uuid
        }).then(data => {
          this.isCollected = true;
          this.canvasDetail = {
            ...this.canvasDetail,
            collectCount: this.canvasDetail.collectCount + 1
          }
        });
      }
    },
    collect() {
      let isLogin = this.isLogin();
      if(!isLogin) {
        this.showLogin = true;
        this.loginCbByField = 'collect';
        // this.$router.push('/Login');
        return;
      }
      this.PostCollectCb();
    },
    getCanvasDetail() {
      GetCanvasDetail({
        uuid: this.uuid
      }).then(data => {
        this.canvasDetail = data;
        this.getBanner(data.imageUrl);
        const fileList =[];
        const result = [];
        JSON.parse(data.bluePrintPath).forEach((item) => {
          [...item.relativePath.split('/').map((Pathitem, index) => {
            fileList.push({
              level: index,
              text: Pathitem
            })
          })]
        })
        fileList.sort((a, b) => {
          return a.level - b.level;
        })
        fileList.forEach(item => {
          if(!result.some(resItem => resItem.level === item.level && resItem.text === item.text)) {
            result.push(item);
          }
        })
        this.fileList = [...result]
        let obj = {};
        result.forEach(item => {
          obj[item.level] = [item.level];
        })
        this.getCurrentPath(result);
        this.levelLen = Object.keys(obj).length;
			})
      this.isLogin() && this.getCanvasDetailIsCollect()
    },
    getCanvasDetailIsCollect() {
      GetCanvasDetailIsCollect({
        uuid: this.uuid
      }).then(data => {
        this.isCollected = (data === 1 ? true : false);
			})
    },
    loadingStart() {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    PostCanvasRecord() {
      let isLogin = this.isLogin();
      if(isLogin) {
        PostCanvasBrowse({
          uuid: this.uuid
        }).then(() => {
          console.log('已登陆 记录浏览次数')
        });
      } else {
         PostCanvasBrowseNoAuth({
          uuid: this.uuid
        }).then(() => {
          console.log('未登陆 记录浏览次数')
        });
      }
    },
    recommendList() {
      RecommendList({
        uuid: this.uuid
      }).then(data => {
        this.recommendLists = [...data];
			})
    }
  },
  mounted() {
    this.uuid = this.$route.query.uuid;
    this.getCanvasDetail();
    this.PostCanvasRecord();
    this.recommendList();
    this.getShareCode();
    document.onclick = () => {
      this.showShareCode = false;
    }
  },
  computed: {
  },
}
</script>
<style scoped>
.slider-info {
  padding: 30px 60px 60px;
}
.slider-info-list {
  background-color: #e8e8e8;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-info-list >>> .el-image {
  height: 32px;
  width: 32px;
  border: 1px solid #909090;
  margin: 0 16px;
}
.common-title {
  background-color: var(--main-color);
  height: 62px;
  line-height: 63px;
  padding-left: 36px;
  font-size: 24px;
  color: #fff;
}
.product-detail-fields {
  line-height: 50px;
  padding: 16px 70px;
  font-size: 20px;
}
.product-detail-files li {
  /* display: flex; */
  list-style: none;
  align-items: center;
  /* justify-content: center; */
  background-color: #e8e8e8;
  font-size: 20px;
}
.product-detail-files li:nth-child(2n) {
  background-color: #fff;
}
.product-detail-files li div {
  flex: 1;
}
.product-detail-files li span {
  padding: 10px;
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.product-detail-files li img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}
.product-recommoned {
  margin: 10px 10px 0;
  background-color: #fff;
}
.product-recommoned-info {
  padding: 10px;
}
.product-recommoned-info ul {
  display: flex;
}
.product-recommoned-info li {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* padding: 10px; */
  margin: 0 40px;
  flex-direction: column;
}
.product-recommoned-info li:nth-child(2n) {
  background-color: #fff;
}
.product-recommoned-info li div {
  /* flex: 1; */
  line-height: 24px;
  font-size: 18px;
  color: #979797;
}
.product-recommoned-info li img {
  height: 214px;
  width: 214px;
  margin-bottom: 10px;
  border: 1px solid #909090;
}
.product-detail {
  background-color: #dedede;
}
.product-detail-bc >>> .el-breadcrumb__separator {
  color: #000;
}
.product-detail-bar {
  height: 80px;
  display: flex;
  padding: 0 30px;
  align-items: center;
	/* box-shadow: 1px 1px 3px 3px rgba(0,0,0,.1); */
  border-bottom: 2px solid rgba(0,0,0,.4);
}
.product-detail-bar img {
  width: 160px;
  height: 54px;
  float: left;
}
.product-detail-bar-right {
  /* flex: 1; */
  display: flex;
  justify-content: end;
  align-items: center;
  float: right;
  height: 42px;
	margin-top: 4px;
  text-align: center;
  color: var(--main-color);
  margin-left: auto;
}
.product-detail-bar-right > div {
 cursor: pointer;
 border-right: 2px solid #999;
 padding: 6px 20px;
}
.product-detail-bar-right > div:nth-last-child(1) {
  border-right: 0;
}
.product-detail-bar-right > div i {
	color: var(--main-color);
	font-size: 22px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.product-detail-content {
  display: flex;
  padding: 10px 0 0 10px;
}
.product-detail-content-left {
  flex: 1;
  background-color: #fff;
  /* height: 1152px; */
  /* overflow-y: auto; */
}
.product-detail-content-left > span {
  padding: 10px;
}
.product-detail-content-right {
  /* width: 560px; */
  flex: 1;
  margin-left: 12px;
  background-color: #fff;
  /* padding: 6px 12px 80px; */
  font-family: Microsoft YaHei;
  /* height: 1152px; */
  /* overflow-y: auto; */
  box-sizing: border-box;
}
.product-detail-content-right-info {
  /* padding-bottom: 20px; */
}
.product-detail-content-right-info .info-title {
  height: 64px;
  line-height: 64px;
  font-size: 18px;
  padding-left: 20px;
  background-color: #e8e8e8;
  color: var(--main-color);
}
.product-detail-content-right-info .info-disc {
  padding: 14px 40px 20px;
  /* text-indent: 2em; */
  font-size: 18px;
  line-height: 28px;
  color: #666;
}
.product-detail >>> .form_login {
  margin: 0 auto;
}
.product-detail >>> .logo-form-box {
  margin-right: 0;
}
.slider_img {
  width: 100%;
  height: 100%;
}
.gap {
	height: 36px;
}
.brand_box {
	/* padding: 24px 0 28px; */
  padding-bottom: 30px;
	font-family: Microsoft YaHei;
}
.brand_item {
  width: 272px;
  height: 426px;
  background-color: #fff;
	/* margin: 10px; */
  margin: 12px 0 12px 30px;
  float: left;
	line-height: 20px;
	border-bottom: 4px solid #939393;
	cursor: pointer;
}
.brand_item:hover {
	border-bottom: 4px solid #7dbd25;
}
.brand_item:hover .brand_operate div p,
.brand_item:hover .brand_operate i {
	color:#7dbd25;
}

.brand_item_img .brand_logo_img {
  width: 90px;
  height: 36px;
  margin: 0 26px 0 auto;
  display: block;
}
.brand_item_img .product_img {
  width: 246px;
  height: 264px;
  margin: 0 auto;
  display: block;
	border: 1px solid #dedede;
}

.brand_operate {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 42px;
	margin-top: 4px;
  text-align: center;
}
.brand_operate div {
 cursor: pointer;
}
.brand_operate i {
	color: #939393;
	font-size: 22px;
	/* width: 24px;
	height: 24px; */
}

.textStyle{
  display: inline-block;
  width: 80px;
  text-align-last: justify;
}

.compatible {
  margin-left: 2px;
}

.notes {
  color: #939393;
  font-size: 12px;
  margin: 0 18px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.notes span {
  margin-right: 5px;
}

.brand_div_title {
	text-overflow: ellipsis;
	overflow: hidden;
  color: #939393;
  font-size: 12px;
  text-align: center;
	height: 24px;
	line-height: 24px;
  margin: 6px auto;
	white-space: nowrap;
	width: 80%;
}

.brand_operate div p {
  color: #939393;
	/* margin-top: 4px; */
  font-size: 12px;
	/* height: 16px; */
	/* line-height: 16px; */
}
.product-detail > .el-image {
  display: none;
}

.share_tools {
  position: absolute;
  right: 0;
  top: 50px;
  background-color: #fff;
  width: 140px;
  height: 150px;
  display: none;
  align-items: center;
  justify-content: center;
}
.share_tools.show {
  display: flex;
}
</style>


