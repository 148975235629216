<template>
	<div class="logo-form-box">
		<div class="form-pos">
			<form v-show="!isThirdPartyLogin" id="form_login" class="form_login ng-pristine ng-valid" method="post" action="/server/signin">
				<div class="clearfix form-title-text">
					<div :class="['title-tabs', 'title-tab-account', loginType === 1 && 'active']" @click="handleTab(1)">
						账号登录
					</div>
					<div :class="['title-tabs', 'title-tab-code', loginType === 2 && 'active']" @click="handleTab(2)">
						验证码登录
					</div>
				</div>
				<div class="error-wrap">
					<div :class="['error-text', showerror ? 'show' : '']">
						<span class="error-hint-html ng-binding">{{errorText}}</span>
					</div>
				</div>
				<div class="clearfix" v-show="loginType == 1">
					<div class="logo-input-user">
						<!-- <i class="icon user-icon"></i> -->
						<input type="text" id="userInfoName" name="username" placeholder="请输入手机号/用户名/邮箱" v-model="text" class="ng-pristine ng-valid">
					</div>
					<div class="logo-input-pwd">
						<!-- <i class="icon pwd-icon"></i> -->
						<input type="password" id="pwdInfo" name="password" v-model="pwd" placeholder="请输入密码" class="ng-pristine ng-valid">
					</div>
				</div>
				<div class="clearfix ng-hide" v-show="loginType == 2">
					<div class="logo-input-user">
						<!-- <i class="icon user-icon"></i> -->
						<input type="text" id="userInfoName2" name="phoneNum" placeholder="请输入手机号" v-model="mobileNum" ng-focus="focusInput()" class="ng-pristine ng-valid">
					</div>
					<div class="logo-input-code">
						<input type="text" id="pwdInfo2" name="smsCode" v-model="smsCode" placeholder="请输入短信验证码" class="ng-pristine ng-valid">
						<button type="button" @click="getMobileCode" class="get-verity-code">
							<span v-if="!getCoding" class="ng-binding">{{codeTime}}秒后重新获取</span>
							<span v-else>
								获取验证码
							</span>
						</button>
					</div>
				</div>
				<div class="clearfix vaptcha-token-container" style="display:none">
					<div class="cancel-vaptcha-container">
						<img @click="cancelVaptchaToken" src="../../assets/other/icon_qingchu@2x.png">
					</div>
					<input type="hidden" id="vaptchaToken" name="vaptchaToken" ng-value="vaptchaToken"
					value="">
					<div id="vaptchaContainer" class="vaptcha-container">
					</div>
				</div>
				<input type="button" class="login-btn" @click="handleSubmit" value="登录">
				<div class="login-regist">
					<span class="regist-line" @click="handleToSignup">
						<!-- <i class="regist"></i> -->
						立即注册
					</span>
					<span class="fr" @click="handleForgetPwd">
						<span class="forgetpwd">
							忘记密码？
						</span>
					</span>
				</div>
				<div class="third-party">
					第三方：
					<img @click="handleThirdPartyLogin('wechat')" src="../../assets/other/wechat.png"
					alt="微信">
				</div>
			</form>
			<!--微信登录 Start-->
			<form v-show="isThirdPartyLogin"
			method="post" id="third-party-login" class="form_login third-party-login ng-pristine ng-valid ng-hide"
			action="/server/signin">
				<!--微信登录中 Start-->
				<div v-show="!isBindUser" class="">
					<img class="close" src="../../assets/other/icon_qingchu@2x.png" alt="返回" @click="handleCloseThirdPartyLogin()">
					<div class="login-loading" v-show="thirdPartyLoginCode">
						<input class="hidden-input ng-pristine ng-valid" type="text" name="code"
						v-model="thirdPartyLoginCode">
						<img src="../../assets/other/wechat.png" alt="微信">
						微信登录中...
					</div>
					<iframe v-show="!thirdPartyLoginCode" ng-src="https://open.weixin.qq.com/connect/qrconnect?appid=wxe18636e0f178696b&amp;redirect_uri=https%3A%2F%2Fpassport.zkh.com%2Fserver%2Fsignin.html&amp;response_type=code&amp;scope=snsapi_login&amp;state=https%3A%2F%2Fpassport.zkh.com%2Fserver%2Fsignin.html"
					title="login" frameborder="0" scrolling="no" width="300px" height="400px"
					sandbox="allow-same-origin allow-top-navigation allow-forms allow-scripts"
					src="https://open.weixin.qq.com/connect/qrconnect?appid=wxe18636e0f178696b&amp;redirect_uri=https%3A%2F%2Fpassport.zkh.com%2Fserver%2Fsignin.html&amp;response_type=code&amp;scope=snsapi_login&amp;state=https%3A%2F%2Fpassport.zkh.com%2Fserver%2Fsignin.html"
					class="">
					</iframe>
				</div>
				<!--微信登录中 End-->
				<!--微信登录绑定 Start-->
				<div v-show="isBindUser" class="ng-hide">
					<img class="close" src="../../assets/other/icon_qingchu@2x.png" alt="返回" @click="handleCloseThirdPartyLogin()">
					<div class="clearfix form-title-text">
						绑定手机号
					</div>
					<div class="error-wrap">
						<div class="error-text ng-hide" v-show="showerror">
							<span class="error-hint-html ng-binding">
							</span>
						</div>
					</div>
					<div class="clearfix">
						<div class="logo-input-user">
							<i class="icon user-icon">
							</i>
							<input type="text" id="userInfoName2" name="phoneNum" placeholder="请输入手机号" v-model="mobileNum" ng-focus="focusInput()" class="ng-pristine ng-valid">
							<input class="hidden-input ng-pristine ng-valid" type="text" name="bind" v-model="bindValue">
						</div>
						<div class="logo-input-code">
							<input type="text" id="pwdInfo2" name="smsCode" v-model="smsCode" placeholder="请输入短信验证码"
							class="ng-pristine ng-valid">
							<button type="button" @click="getMobileCode" class="get-verity-code">
								<span v-if="getCoding" class="ng-binding ng-hide">
									{{codeTime}}秒后重新获取
								</span>
								<span v-else>
									获取验证码
								</span>
							</button>
						</div>
					</div>
					<input type="button" class="login-btn" @click="handleSubmitThirdParty()"
					value="进入震坤行">
					<div class="login-regist">
						<a ng-href="http://www.zkh.com/user/register/step1" class="regist-line"
						href="http://www.zkh.com/user/register/step1">
							<i class="regist">
							</i>
							立即注册
						</a>
					</div>
				</div>
				<!--微信登录绑定 End-->
			</form>
			<!--微信登录 End-->
		</div>
	</div>
</template>
<script>
import { LoginByPwd, LoginByCode, GetCode, GetUserInfo } from '../../api/login/api'
import Bus from '../../bus'
export default {
  name: 'Login',
  components: {},
  props: [
    'visible'
  ],
  data() {
    return {
			isThirdPartyLogin: false,
			thirdPartyLoginCode: false,
			loginType: 1,
			showerror: false,
      errorText: '',
      getCoding: true,
			codeTime: 60,
			isBindUser: false,
      text: '',
      pwd: '',
      mobileNum: '',
      smsCode: '',
      bindValue: '',
      timer: null
		}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    Bus.$on('showLoginError', val => {
      this.errorText = val.msg;
    });
  },
  methods: {
    ticketing() {
      this.timer = setInterval(() => {
        if(this.codeTime === 1) {
          this.getCoding = true;
          this.codeTime = 60;
          clearInterval(this.timer);
        }
        this.codeTime = --this.codeTime;
      }, 1000);
    },
    cancelVaptchaToken() {

    },
    getMobileCode() {
      if(!(/^1(3|4|5|7|8|9)\d{9}$/).test(this.mobileNum)) {
        this.showerror = true;
        this.errorText = '请输入正确手机号'
        return
      }
      if(!this.getCoding) return;
      // this.getCoding = false;
      // this.ticketing();
      GetCode({
        mobile: this.mobileNum
      }).then(data => {
        if(data.code === 106) {
              
        } else {
          this.getCoding = false;
          this.ticketing();
        }
        this.$toast({ message: data.msg, position: 'middle' })
        // this.$toast({ message: '验证码已发送成功', position: 'middle' })
      })
    },
    handleSubmit() {
      if(this.loginType === 1) {
        if(!this.text) {
          this.showerror = true;
          this.errorText = '请输入账号'
          return
        }
        if(!this.pwd) {
          this.showerror = true;
          this.errorText = '请输入密码'
          return
        }
        this.showerror = false;
        LoginByPwd({
          mobile: this.text,
          passWord: this.pwd
        }).then(data => {
          if (data.code === 109) {
            this.showerror = true;
            this.errorText = data.msg;
          } else {
            if(data) {
              this.$nextTick(() => {
                // Bus.$emit('showLogin', false);
                this.$emit('update:visible', false)
              })
              window.localStorage && window.localStorage.setItem('token', data)
              // 获取用户接口放置首页处理
              this.getUserInfo(data);
            }
          }
        })
      } else if(this.loginType === 2) {
        if(!(/^1(3|4|5|7|8|9)\d{9}$/).test(this.mobileNum)) {
          this.showerror = true;
          this.errorText = '请输入正确手机号'
          return
        }
        if(!this.smsCode) {
          this.showerror = true;
          this.errorText = '请输入验证码'
          return
        }        
        this.showerror = false;
        LoginByCode({
          mobile: this.mobileNum,
          code: this.smsCode
        }).then(data => {
          if (data.code === 108) {
            this.showerror = true;
            this.errorText = data.msg;
          } else {
            if(data) {
              this.$nextTick(() => {
                // Bus.$emit('showLogin', false);
                this.$emit('update:visible', false)
              })
              window.localStorage && window.localStorage.setItem('token', data)
              // 获取用户接口放置首页处理
              this.getUserInfo(data);
            }
          }
        })
      }
    },
    getUserInfo(token) {
      GetUserInfo({
        'auth-token': token
      }).then(data => {
        window.localStorage && window.localStorage.setItem('user', JSON.stringify(data))
				this.$emit('loginCb', data);
        this.$nextTick(() => {
          Bus.$emit('setUser', data)
        })
      })
    },
    handleTab(n) {
			this.loginType = n;
      this.showerror = false;
      this.errorText = '';
		},
		BindUser() {

		},
		handleThirdPartyLogin(name) {
			// this.isThirdPartyLogin = true;
		},
		handleCloseThirdPartyLogin() {
			this.isThirdPartyLogin = false;
		},
		handleToSignup() {
      this.$emit('update:visible', false)
      this.$emit('signupClick')
			// this.$router.push('/Signup')
		},
		handleForgetPwd() {
      this.$emit('update:visible', false)
			this.$router.push('/ForgetPwd')
		},
  }
}

</script>
<style scoped>
body,html {
  -webkit-text-size-adjust: none;
  position: relative;
  height: 100%;
  min-width: 1366px
}

body {
  outline: none;
  padding: 0;
  font: 12px Microsoft Yahei,Arial,sans-serif;
  line-height: 1;
  color: #666;
  background: #fff
}

blockquote,dd,div,dl,dt,fieldset,form,h1,h2,h3,h4,h5,h6,input,li,ol,p,pre,textarea,ul {
  padding: 0;
  margin: 0
}

table,td,th,tr {
  font-size: 12px
}

ol,ul {
  list-style: none
}

li {
  list-style-type: none
}

img {
  vertical-align: top;
  border: 0
}

h1,h2,h3,h4,h5,h6 {
  font-size: inherit;
  font-weight: 400;
}

address,cite,code,em,i,th {
  font-weight: 400;
  font-style: normal
}

a {
  text-decoration: none;
  color: #666;
  cursor: pointer
}

a.noLink {
  cursor: default
}

a.noLink:hover {
  color: #666
}

a:hover {
  color: #ed0c1e
}

a,a:active,a:focus,a:hover,a:link,a:visited {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: none;
  text-decoration: none
}
[class^=ant-] {
  box-sizing: border-box;
  line-height: 1.5
}
.ant-form-horizontal .ant-form-item {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  height: auto;
  zoom: 1;
}
.ant-form-item {
  overflow: hidden;
  margin-bottom: 30px;
}
.ant-row {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  height: auto;
  zoom: 1;
  display: block;
}
.ant-form-item {
  font-size: 12px;
  margin-bottom: 24px;
  color: #666;
}
.register-wrap .ant-col-5 {
    width: 17.8%;
}
.ant-col-5 {
    display: block;
    width: 20.83333333%;
}
.ant-col-1, .ant-col-2, .ant-col-3, .ant-col-4, .ant-col-5, .ant-col-6, .ant-col-7, .ant-col-8, .ant-col-9, .ant-col-10, .ant-col-11, .ant-col-12, .ant-col-13, .ant-col-14, .ant-col-15, .ant-col-16, .ant-col-17, .ant-col-18, .ant-col-19, .ant-col-20, .ant-col-21, .ant-col-22, .ant-col-23, .ant-col-24 {
    float: left;
    flex: 0 0 auto;
}
.ant-col-1, .ant-col-2, .ant-col-3, .ant-col-4, .ant-col-5, .ant-col-6, .ant-col-7, .ant-col-8, .ant-col-9, .ant-col-10, .ant-col-11, .ant-col-12, .ant-col-13, .ant-col-14, .ant-col-15, .ant-col-16, .ant-col-17, .ant-col-18, .ant-col-19, .ant-col-20, .ant-col-21, .ant-col-22, .ant-col-23, .ant-col-24, .ant-col-lg-1, .ant-col-lg-2, .ant-col-lg-3, .ant-col-lg-4, .ant-col-lg-5, .ant-col-lg-6, .ant-col-lg-7, .ant-col-lg-8, .ant-col-lg-9, .ant-col-lg-10, .ant-col-lg-11, .ant-col-lg-12, .ant-col-lg-13, .ant-col-lg-14, .ant-col-lg-15, .ant-col-lg-16, .ant-col-lg-17, .ant-col-lg-18, .ant-col-lg-19, .ant-col-lg-20, .ant-col-lg-21, .ant-col-lg-22, .ant-col-lg-23, .ant-col-lg-24, .ant-col-md-1, .ant-col-md-2, .ant-col-md-3, .ant-col-md-4, .ant-col-md-5, .ant-col-md-6, .ant-col-md-7, .ant-col-md-8, .ant-col-md-9, .ant-col-md-10, .ant-col-md-11, .ant-col-md-12, .ant-col-md-13, .ant-col-md-14, .ant-col-md-15, .ant-col-md-16, .ant-col-md-17, .ant-col-md-18, .ant-col-md-19, .ant-col-md-20, .ant-col-md-21, .ant-col-md-22, .ant-col-md-23, .ant-col-md-24, .ant-col-sm-1, .ant-col-sm-2, .ant-col-sm-3, .ant-col-sm-4, .ant-col-sm-5, .ant-col-sm-6, .ant-col-sm-7, .ant-col-sm-8, .ant-col-sm-9, .ant-col-sm-10, .ant-col-sm-11, .ant-col-sm-12, .ant-col-sm-13, .ant-col-sm-14, .ant-col-sm-15, .ant-col-sm-16, .ant-col-sm-17, .ant-col-sm-18, .ant-col-sm-19, .ant-col-sm-20, .ant-col-sm-21, .ant-col-sm-22, .ant-col-sm-23, .ant-col-sm-24, .ant-col-xs-1, .ant-col-xs-2, .ant-col-xs-3, .ant-col-xs-4, .ant-col-xs-5, .ant-col-xs-6, .ant-col-xs-7, .ant-col-xs-8, .ant-col-xs-9, .ant-col-xs-10, .ant-col-xs-11, .ant-col-xs-12, .ant-col-xs-13, .ant-col-xs-14, .ant-col-xs-15, .ant-col-xs-16, .ant-col-xs-17, .ant-col-xs-18, .ant-col-xs-19, .ant-col-xs-20, .ant-col-xs-21, .ant-col-xs-22, .ant-col-xs-23, .ant-col-xs-24 {
    position: relative;
    min-height: 1px;
    padding-left: 0;
    padding-right: 0;
}
.ant-form-item-label {
    text-align: right;
    vertical-align: middle;
    padding: 7px 0;
    display: inline-block;
}
.ant-form-horizontal .ant-form-item:after, .ant-form-horizontal .ant-form-item:before {
  content: " ";
  display: table;
}
.ant-row:after, .ant-row:before {
  content: " ";
  display: table;
}
[class^=ant-] {
    box-sizing: border-box;
    line-height: 1.5;
}
label {
    position: relative;
}
label {
    font-size: 12px;
}
label {
    cursor: default;
}
.ant-form-item-label label:after {
    content: ":";
    margin: 0 8px 0 2px;
    position: relative;
    top: -0.5px;
}
.ant-form-item label {
    font-size: 16px;
}
.ant-form-item-label label {
    color: #666;
}
.register-wrap .ant-col-19 {
    width: 82.2%;
}
.ant-col-19 {
    display: block;
    width: 79.16666667%;
}
.ant-col-1, .ant-col-2, .ant-col-3, .ant-col-4, .ant-col-5, .ant-col-6, .ant-col-7, .ant-col-8, .ant-col-9, .ant-col-10, .ant-col-11, .ant-col-12, .ant-col-13, .ant-col-14, .ant-col-15, .ant-col-16, .ant-col-17, .ant-col-18, .ant-col-19, .ant-col-20, .ant-col-21, .ant-col-22, .ant-col-23, .ant-col-24 {
    float: left;
    flex: 0 0 auto;
}
.ant-col-1, .ant-col-2, .ant-col-3, .ant-col-4, .ant-col-5, .ant-col-6, .ant-col-7, .ant-col-8, .ant-col-9, .ant-col-10, .ant-col-11, .ant-col-12, .ant-col-13, .ant-col-14, .ant-col-15, .ant-col-16, .ant-col-17, .ant-col-18, .ant-col-19, .ant-col-20, .ant-col-21, .ant-col-22, .ant-col-23, .ant-col-24, .ant-col-lg-1, .ant-col-lg-2, .ant-col-lg-3, .ant-col-lg-4, .ant-col-lg-5, .ant-col-lg-6, .ant-col-lg-7, .ant-col-lg-8, .ant-col-lg-9, .ant-col-lg-10, .ant-col-lg-11, .ant-col-lg-12, .ant-col-lg-13, .ant-col-lg-14, .ant-col-lg-15, .ant-col-lg-16, .ant-col-lg-17, .ant-col-lg-18, .ant-col-lg-19, .ant-col-lg-20, .ant-col-lg-21, .ant-col-lg-22, .ant-col-lg-23, .ant-col-lg-24, .ant-col-md-1, .ant-col-md-2, .ant-col-md-3, .ant-col-md-4, .ant-col-md-5, .ant-col-md-6, .ant-col-md-7, .ant-col-md-8, .ant-col-md-9, .ant-col-md-10, .ant-col-md-11, .ant-col-md-12, .ant-col-md-13, .ant-col-md-14, .ant-col-md-15, .ant-col-md-16, .ant-col-md-17, .ant-col-md-18, .ant-col-md-19, .ant-col-md-20, .ant-col-md-21, .ant-col-md-22, .ant-col-md-23, .ant-col-md-24, .ant-col-sm-1, .ant-col-sm-2, .ant-col-sm-3, .ant-col-sm-4, .ant-col-sm-5, .ant-col-sm-6, .ant-col-sm-7, .ant-col-sm-8, .ant-col-sm-9, .ant-col-sm-10, .ant-col-sm-11, .ant-col-sm-12, .ant-col-sm-13, .ant-col-sm-14, .ant-col-sm-15, .ant-col-sm-16, .ant-col-sm-17, .ant-col-sm-18, .ant-col-sm-19, .ant-col-sm-20, .ant-col-sm-21, .ant-col-sm-22, .ant-col-sm-23, .ant-col-sm-24, .ant-col-xs-1, .ant-col-xs-2, .ant-col-xs-3, .ant-col-xs-4, .ant-col-xs-5, .ant-col-xs-6, .ant-col-xs-7, .ant-col-xs-8, .ant-col-xs-9, .ant-col-xs-10, .ant-col-xs-11, .ant-col-xs-12, .ant-col-xs-13, .ant-col-xs-14, .ant-col-xs-15, .ant-col-xs-16, .ant-col-xs-17, .ant-col-xs-18, .ant-col-xs-19, .ant-col-xs-20, .ant-col-xs-21, .ant-col-xs-22, .ant-col-xs-23, .ant-col-xs-24 {
    position: relative;
    min-height: 1px;
    padding-left: 0;
    padding-right: 0;
}
.ant-form-item-control {
    line-height: 32px;
    position: relative;
    zoom: 1;
    font-size: 14px !important;
}
.ant-form-item-control:after, .ant-form-item-control:before {
    content: " ";
    display: table;
}
.ant-form-item-label {
    text-align: right;
    vertical-align: middle;
    padding: 7px 0;
    display: inline-block;
}
.ant-form-item {
    font-size: 12px;
    margin-bottom: 24px;
    color: #666;
}
.ant-input {
    position: relative;
    display: inline-block;
    padding: 4px 7px;
    width: 100%;
    height: 28px;
    cursor: text;
    font-size: 12px;
    line-height: 1.5;
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    transition: border .2s cubic-bezier(.645,.045,.355,1),background .2s cubic-bezier(.645,.045,.355,1),box-shadow .2s cubic-bezier(.645,.045,.355,1);
}
.ant-input {
    font-size: 16px;
    font-weight: 400;
    padding: 15px 10px;
    height: 50px !important;
    line-height: 50px !important;
    border: 1px solid #dedede;
    border-radius: 3px;
    box-shadow: none;
}
.ant-input-lg {
    padding: 6px 7px;
    height: 32px;
}
.ant-input::-moz-placeholder {
    color: #ccc;
    opacity: 1
}

.ant-input:-ms-input-placeholder {
    color: #ccc
}

.ant-input::-webkit-input-placeholder {
    color: #ccc
}

.ant-input:focus,.ant-input:hover {
    border-color: #858585
}

.ant-input:focus {
    outline: 0;
    box-shadow: 0 0 0 2px hsla(0,0%,40%,.2)
}

.ant-input[disabled],fieldset[disabled] .ant-input {
    background-color: #f7f7f7;
    opacity: 1;
    cursor: not-allowed;
    color: #ccc
}

.ant-input[disabled]:hover,fieldset[disabled] .ant-input:hover {
    border-color: #e1e1e1
}

.ant-btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    line-height: 1.5;
    padding: 4px 15px;
    font-size: 12px;
    border-radius: 6px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    position: relative;
    color: #666;
    background-color: #f7f7f7;
    border: 1px solid #d9d9d9;
}
.ant-btn {
    font-size: 16px;
    font-weight: 400;
    padding: 10px 42px;
    border-radius: 3px;
}
.ant-btn-primary {
    color: #fff;
    background-color: #666;
    border-color: #666;
}
.ant-btn-lg {
    padding: 4px 15px 5px;
    border-radius: 6px;
}
.ant-btn, .ant-btn:active, .ant-btn:focus {
    outline: 0;
}
.submit-btn {
    width: 100%;
    height: 46px;
    background: #ed0c1e;
    margin-top: 30px;
}

.ant-btn-primary, .ant-btn-primary:hover {
    color: #fff;
    background: #f74848;
    border: none;
}
form input[type=checkbox], form input[type=radio] {
    width: 14px;
    height: 14px;
}
input[type=checkbox], input[type=radio] {
    line-height: normal;
}
.ant-checkbox-input {
    position: absolute;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.step-1 .ant-checkbox .ant-checkbox-inner {
    width: 16px;
    height: 16px;
}
.ant-checkbox .ant-checkbox-inner {
    box-sizing: content-box;
    width: 12px;
    height: 12px;
}
.ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    top: 50%;
    transform: translate(0, 18%);
}
.ant-checkbox-inner:after {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
    position: absolute;
    left: 4px;
    top: 1px;
    display: table;
    width: 5px;
    height: 8px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    content: " ";
    transition: all .1s cubic-bezier(.71,-.46,.88,.6);
}
body {
  margin: 0;
  outline: none;
  padding: 0;
  font: 12px/1.5 '宋体', Arial, sans-serif;
  font-family: '微软雅黑';
  line-height: 160%;
  color: #666;
  background: #ffffff;
}
a {
  color: #666;
  text-decoration: none;
}
.clearfix:after {
  display: block;
  content: '';
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.remember-me {
  cursor: pointer;
  margin-top: 19px;
  font-size: 12px;
}
.remember-me input.checkbox {
  position: relative;
  top: 2px;
  left: 4px;
}
.is-remember {
  background: url(../../assets/other/check_red.png) 0 0 no-repeat #fff;
  background-position: 4px 1px;
  padding-left: 25px;
  position: relative;
  left: -18px;
  z-index: 1;
}
.no-remember {
  background: url(../../assets/other/check_.png) 0 0 no-repeat #fff;
  background-position: 4px 1px;
  padding-left: 25px;
  position: relative;
  left: -18px;
  z-index: 1;
}
.logo-tit a img {
  width: 211px;
  vertical-align: top;
  margin-top: 2px;
}
.logo-header {
  margin: 20px 0;
  height: 60px;
}
.logo-context {
  width: 1152px;
  margin: 0 auto;
}
.logo-header .logo-text {
  display: inline-block;
  font-size: 24px;
  color: #ed0c1e;
  border-left: 1px solid #dcdcdc;
  margin-top: 18px;
  margin-left: 20px;
  padding: 5px 0px 5px 20px;
}
.logo-content-bg img {
  width: 240px;
  height: 240px;
  position: absolute;
  left: 20%;
  bottom: 0;
}
.logo-form-box {
  /* margin-right: 16%; */
  flex: 1;
}
.form-pos {
  /* float: right; */
  /* width: 934px; */
  height: 100%;
  box-sizing: border-box;;
  padding: 35px 0 20px 0;
  /* margin-right: 20%; */
  font-size: 14px;
  background: none;
  /* border: 1px solid #9D9D9D; */
  display: flex;
  align-items: center;
}

.form_login {
  position: relative;
  /* width: 320px; */
  height: 386px;
  /* margin-top: 6px; */
  /* margin-left: -60%; */
  background-color: #fff;
  padding: 15px 40px;
  width: 70%;
  margin: 0 auto;
}
.form-title-text {
  text-align: center;
  font-size: 22px;
  /* font-weight: bold; */
  padding: 20px 0;
  background-color: var(--main-color);
  color: #fff;
}
.title-tabs {
  float: left;
  width: 50%;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  opacity: 0.8;
}
.title-tabs.active {
  font-weight: bold;
  /* font-size: 20px; */
  opacity: 1;
}
.title-tab-account {
  border-right: 2px solid #fff;
}
.error-wrap {
  height: 24px;
  width: 284px;
  margin-top: 10px;
}
.error-text {
  height: 24px;
  width: 284px;
  padding-left: 23px;
  background: url(../../assets/other/error_icon.png) no-repeat 10px center;
  font-size: 20px;
  color: #eb4452;
  line-height: 24px;
  background-position: left;
  opacity: 0;
}
.error-text.show {
  opacity: 1;
}
.logo-input-user,
.logo-input-pwd {
  /* float: left; */
  /* width: 318px; */
  border: 1px #e2e2e2 solid;
  /* margin-top: 10px; */
  position: relative;
  border-radius: 3px;
  height: 48px;
}
.logo-input-pwd {
  margin-top: 30px;
}
.icon {
  float: left;
  height: 48px;
  line-height: 40p48pxx;
  width: 8%;
  text-align: center;
  background-color: #f1f1f1;
}
.user-icon {
  background: url(../../assets/other/login_person.png) no-repeat center center #f1f1f1;
}
.pwd-icon {
  background: url(../../assets/other/login_password.png) no-repeat center center #f1f1f1;
}
#userInfoName,
#pwdInfo,
#userInfoName2 {
  /* float: left; */
  width: 100%;
  padding-left: 10px;
  padding-right: 40px;
  line-height: 48px;
  height: 48px;
  border: none;
  font-size: 18px;
  font-family: '微软雅黑';
  box-sizing: border-box;
}

.logo-input-code {
  /* float: left; */
  /* width: 318px; */
  margin-top: 30px;
  display: flex;
  height: 48px;
}
#pwdInfo2 {
  /* float: left; */
  width: 60%;
  padding-left: 10px;
  line-height: 40px;
  border: 1px #e2e2e2 solid !important;
  border-radius: 3px;
  height: 48px;
  border: none;
  font-size: 20px;
  font-family: '微软雅黑';
  flex: 1;
  margin-right: 10px;
}
.get-verity-code {
  float: right;
  width: 40%;
  height: 48px;
  background: #f2f2f2;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.logo-forgetpwd {
  text-align: right;
}
.forgetpwd {
  display: inline-block;
  margin-top: 26px;
  font-size: 20px;
  cursor: pointer;
}
.login-btn {
  background: var(--main-color);
  color: #fff;
  width: 100%;
  text-align: center;
  height: 45px;
  line-height: 45px;
  font-size: 22px;
  border-radius: 3px;
  text-decoration: none;
  cursor: pointer;
  margin: 34px auto 0;
  display: block;
  border: none;
}
.regist-line {
  font-size: 20px;
  color: #e50112;
  display: inline-block;
  margin-top: 26px;
  position: relative;
  /* padding-left: 20px; */
	cursor: pointer;
}
.regist-line .regist {
  background: url(../../assets/other/zc_icon.png) no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0px;
  top: 2px;
	background-size: contain;
}

.icon-item {
  margin: 0 auto;
  width: 1152px;
}
img {
  vertical-align: top;
  border: 0;
}
.icon-item img {
  margin: 36px 10px 0 66px;
}
.icon-item span {
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  margin-top: 50px;
  font-family: 'Microsoft yahei';
}
.icon-item div {
  display: inline-block;
}

.foot-icon-content {
  margin: 0 auto;
  width: 1152px;
  text-align: center;
}
.logo-foot-line {
  margin-top: 30px;
}
.logo-foot-line a {
  display: inline-block !important;
  line-height: 24px;
  color: #666 !important;
  font-size: 12px !important;
  margin-right: 12px;
}
.de_line {
  height: 14px;
  width: 1px;
  background: #666;
  position: relative;
  top: 2px;
}
input::-webkit-input-placeholder {
  color: #e2e2e2;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #e2e2e2;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #e2e2e2;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #e2e2e2;
}
.modal-dialog {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -100px;
  margin-left: -130px;
  width: 260px;
  background: #fff;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  box-sizing: border-box;
}
.modal-dialog .modal-title {
  height: 32px;
  line-height: 32px;
  background: #c8c8c8;
}
.modal-dialog .modal-content {
  padding: 30px 10px;
}
.modal-dialog .close-modal {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  background: url(../../assets/other/close_03.png) no-repeat center center;
  cursor: pointer;
}

.vaptcha-token-container {
  top: 20px;
  bottom: 75px;
  left: 20px;
  right: 20px;
  position: absolute;
  background-color: #ffffff;
  /* opacity: 0.5; */
  z-index: 10;
  box-shadow: 0 0 2px 2px #eee;
  border: 1px solid #eee;
}
.cancel-vaptcha-container {
  text-align: right;
  height: 15px;
  margin: 15px;
}
.cancel-vaptcha-container img {
  height: 100%;
  cursor: pointer;
}
.vaptcha-token-container .vaptcha-container {
  margin-top: 10px;
}

.third-party {
  display: flex;
  align-items: center;
  padding-top: 16px;
  font-size: 20px;
}

.third-party img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.third-party-login .close {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  width: 14px;
}

.third-party-login .login-btn {
  margin-top: 40px;
}

.third-party-login .login-loading {
  text-align: center;
  font-size: 18px;
  margin-top: 120px
}

.third-party-login .login-loading img {
  margin: 0 auto;
  width: 60px;
  display: block;
  margin-bottom: 28px;
}

.third-party-login .hidden-input {
  display: none;
}
</style>

