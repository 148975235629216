<template>
  <div class="logo-content">
    <div class="logo-content-bg">
      <img src="../assets/other/wechat.jpeg" />
    </div>
    <login-info @loginCb="loginCb"></login-info>
	</div>
</template>
<script>
import LoginInfo from './public/loginInfo.vue'
export default {
  name: 'Login',
  components: {
    LoginInfo
  },
  props: [],
  data() {
    return {
			isThirdPartyLogin: false,
			thirdPartyLoginCode: false,
			loginType: 1,
			showerror: false,
      errorText: '',
      getCoding: true,
			codeTime: 60,
			isBindUser: false,
      text: '',
      pwd: '',
      mobileNum: '',
      smsCode: '',
      bindValue: '',
      timer: null
		}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    loginCb() {
      this.$router.push('/')
    }
  }
}

</script>
<style scoped>
body,html {
  -webkit-text-size-adjust: none;
  position: relative;
  height: 100%;
  min-width: 1366px
}

body {
  outline: none;
  padding: 0;
  font: 12px Microsoft Yahei,Arial,sans-serif;
  line-height: 1;
  color: #666;
  background: #fff
}

blockquote,dd,div,dl,dt,fieldset,form,h1,h2,h3,h4,h5,h6,input,li,ol,p,pre,textarea,ul {
  padding: 0;
  margin: 0
}

table,td,th,tr {
  font-size: 12px
}

ol,ul {
  list-style: none
}

li {
  list-style-type: none
}

img {
  vertical-align: top;
  border: 0
}

h1,h2,h3,h4,h5,h6 {
  font-size: inherit;
  font-weight: 400;
}

address,cite,code,em,i,th {
  font-weight: 400;
  font-style: normal
}

a {
  text-decoration: none;
  color: #666;
  cursor: pointer
}

a.noLink {
  cursor: default
}

a.noLink:hover {
  color: #666
}

a:hover {
  color: #ed0c1e
}

a,a:active,a:focus,a:hover,a:link,a:visited {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: none;
  text-decoration: none
}
[class^=ant-] {
  box-sizing: border-box;
  line-height: 1.5
}
body {
  margin: 0;
  outline: none;
  padding: 0;
  font: 12px/1.5 '宋体', Arial, sans-serif;
  font-family: '微软雅黑';
  line-height: 160%;
  color: #666;
  background: #ffffff;
}
a {
  color: #666;
  text-decoration: none;
}
.clearfix:after {
  display: block;
  content: '';
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.remember-me {
  cursor: pointer;
  margin-top: 19px;
  font-size: 12px;
}
.remember-me input.checkbox {
  position: relative;
  top: 2px;
  left: 4px;
}
.is-remember {
  background: url(../assets/other/check_red.png) 0 0 no-repeat #fff;
  background-position: 4px 1px;
  padding-left: 25px;
  position: relative;
  left: -18px;
  z-index: 1;
}
.no-remember {
  background: url(../assets/other/check_.png) 0 0 no-repeat #fff;
  background-position: 4px 1px;
  padding-left: 25px;
  position: relative;
  left: -18px;
  z-index: 1;
}
.logo-header {
  margin: 20px 0;
  height: 60px;
}
.logo-context {
  width: 1152px;
  height: 640px;
  margin: 0 auto;
}
.logo-header .logo-text {
  display: inline-block;
  font-size: 24px;
  color: #ed0c1e;
  border-left: 1px solid #dcdcdc;
  margin-top: 18px;
  margin-left: 20px;
  padding: 5px 0px 5px 20px;
}
.logo-content {
  background: url('../assets/other/loginbg.jpg') no-repeat center #fff;
  height: 500px;
  margin: 30px 0;
  position: relative;
  display: flex;
  background-size: cover;
}
.logo-content-bg {
  height: 500px;
  flex: 1;
  position: relative;
}
.logo-content-bg img {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 20%;
  bottom: 0;
}
.logo-form-box {
  /* width: 1152px; */
  /* margin-right: 16%; */
  /* flex: 1; */
}
</style>

