<template>
	<div class="paging">
		<el-pagination
			background
			:hide-on-single-page="show"
			next-text="下一页"
			prev-text="上一页"
			@size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
			:page-size="pageSize"
			layout="prev, pager, next, jumper, total"
			:total="total">
		</el-pagination>
	</div>
</template>
<script>
export default {
	name: 'menuList',
  props: {
   total: {
		 type: Number,
		 default: 0,
	 },
	 pageSize: {
		 type: Number,
		 default: 10,
	 },
  },
  data() {
    return {
		 currentPage: 1,
		 show: true,
    }
  },
	mounted() {
	},
	methods: {
		handleSizeChange(val) {
			// console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			// console.log(`当前页: ${val}`);
			this.$emit('handleCurrentChange', val);
		}
	}
}
</script>

<style scoped>
.paging {
  background-color: #ccc;
  width: 100%;
  height: 70px;
  text-align: center;
  line-height: 70px;
	position: relative;
  box-sizing: border-box;
	font-family: Microsoft YaHei;
}
.paging >>> .el-pagination {
	padding: 0;
	transform: translate(-50%, -25%);
	position: absolute;
	left: 50%;
	top: 50%;
}
.paging >>> .el-pagination__total {
 margin-left: 20px;
}
.el-pagination.is-background .el-pager li:hover {
  color: var(--main-color);
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: var(--main-color);
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  background-color: var(--main-color);
  color: #fff;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: var(--main-color);
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
  background-color: #ccc;
  color: #fff;
}
</style>