<template>
  <div class="new_product_release_div">
    <div class="right_content content_all">
      <div>
        <div v-if="tagList.length || key" class="right_search_div">
          <span>全部结果：</span>
          <div class="right_search_input">
            <div v-for="(item, index) in tagList" :key="index" class="right_search_input_info">
              <div class="right_search_input_tag">
                <strong>{{getTagName(item)}}:{{item.name}}</strong>
                <b @click="deleteTag(item)">x</b>
              </div>
              <span> &gt; </span>
            </div>
          </div>
          <span v-if="key" class="right_search_key">"{{key}}"<b @click="deleteSearchText">x</b></span>
        </div>
        <goods-search v-if="queryData.length" :queryData="queryData" @brandItemHandleClick="brandItemHandleClick" @handleClickProductItem="handleClickProductItem" @handleClickIndustryItem="handleClickIndustryItem"></goods-search>
      </div>
      <div class="brand_div">
        <pic-list :videoList="videoList" @VideoHandleClick="VideoHandleClick"></pic-list>
        <div class="clear_both"></div>
        <paging v-if="total > pageSize" :total="total" @handleCurrentChange="handleCurrentChange"></paging>
      </div>
    </div>
  </div>
</template>
<script>
import { baseUrl } from '../utils'
import goodsSearch from './public/goodsSearch.vue'
import picList from './public/picList.vue'
import paging from './public/paging.vue'
import {GetPicList} from "@/api/pdf/api";
export default {
  name: 'ProductPic',
  data() {
    return {
      searchList: {

      },
      fieldType: {
        brandType: '品牌',
        productType: '产品',
        industryType: '工业'
      },
      baseUrl,
      // 是否展示菜单
      menuShow: true,
      // 菜单列表
      menuDataList: [],
      // 产品总条数
      total: 0,
      // 产品列表
      videoList: [],
      // 当前页数
      currentPage: 1,
      // 每页条数
      pageSize: 20,
      // brand 品牌筛选
      brandId: 0,
      // 三级条件
      thirdCatId: 0,
      fourCatId: 0,
      // 产品分类
      productType: 0,
      subCatId: 0,
      // 产业分类
      industryType: 0,
      // 搜索文字
      key: '',
      // 筛选条件
      queryData: [],
      // 是否有搜索条件
      searching: false,
      // 条件列表
      tagList: [],
      itemId: 0,
      itemName: 0,
      industryFirstCatId: 0,
      industrySecordCatId: 0,
      companyId: ''
    }
  },
  watch: {
  },
  components: {
    goodsSearch,
    picList,
    paging
  },
  methods: {
    brandItemHandleClick(item) {
      item.key = 'brandId';
      item.level = 1;
      this.pushTagList(item);
      this.getPDFList();
      this.initPage();
    },
    handleClickProductItem(item) {
      item.key = 'productType';
      item.level = 2;
      if(item.fieldName === 'subCatId') {
        item.level = 2;
      } else if(item.fieldName === 'thirdCatId') {
        item.level = 3;
      } else if(item.fieldName === 'fourCatId') {
        item.level = 4;
      }
      this.pushTagList(item);
      this.getPDFList();
      this.initPage();
    },
    handleClickIndustryItem(item) {
      item.key = 'industryType';
      item.level = 5;
      if(item.fieldName === 'industryFirstCatId') {
        item.level = 5;
      } else if(item.fieldName === 'industrySecordCatId') {
        item.level = 6;
      }
      this.pushTagList(item);
      this.getPDFList();
      this.initPage();
    },
    VideoHandleClick(item) {
      this.$router.push({
        path: '/Pdf',
        query: {
          pdfIdList: item.pdfIdList
        }
      });

    },
    handleCurrentChange(value) {
      this.currentPage = value;
      this.getPDFList();
    },
    getPDFList() {
      GetPicList({
        'brandId': this.brandId,
        'industryFirstCatId': this.industryFirstCatId,
        'industrySecordCatId': this.industrySecordCatId,
        'thirdCatId': this.thirdCatId,
        'fourCatId': this.fourCatId,
        'subCatId': this.subCatId,
        'key': this.key,
        'pageNum': this.currentPage,
        'pageSize': this.pageSize,
         'companyId': this.companyId,
      }).then(data => {
        let videoList = [...data.list];
        let letData = [];
        for (let i = 0; i < videoList.length; i++) {
            let split = videoList[i].imageUrl.split(',');
            for (let j = 0; j < split.length; j++) {
                let udfData = {};
                let img = split[j];
                if (!img.startsWith("http")) {
                    img = "https://andrnd.com" + img;
                }
                udfData.imageUrl = img;
                udfData.brandName = videoList[i].brandName;
                if (!udfData.brandName) {
                    udfData.brandName = videoList[i].brandEnglishName;
                }
                udfData.pdfIdList = videoList[i].pdfIdList;
                udfData.pictureName = videoList[i].pictureName;
                udfData.brandImageUrl = videoList[i].brandImageUrl;
                letData.push(udfData);
            }
        }
        this.videoList = letData;
        this.total = data.total;
        data.queryData.forEach(item => {
          let {data, name, type} = item;
          data.length && data.forEach(dataItem => {
            dataItem.fieldName = name;
            dataItem.fieldType = type;
          })
        });
        this.queryData = [...data.queryData];
      })
    },
    pushTagList(item) {
      let tagList = [...this.tagList];
      tagList = tagList.filter(tag => (tag.level !== item.level));
      tagList.push(item);
      this[item.fieldName] = item.value;
      this.$nextTick(() => {
        tagList.sort((a, b) => {
          return a.level - b.level;
        });
        this.tagList = [...tagList];
      });
    },
    getTagName(item) {
      return this.fieldType[item.fieldType];
    },
    deleteTag(item) {
      this.deleteTagList(item);
      this.initValue(item.fieldType, item.fieldName);
      this.tagList.forEach(item => this[item.key] = item.value);
      this.getPDFList();
    },
    deleteTagList(item) {
      let tagList = this.tagList.filter(tag => {
        let {fieldType: type, fieldName, level} = item;
        if(type === 'brandType') {
          return tag.fieldType !== type;
          // return tag.level !== level;
        } else if(type === 'softType') {
          return tag.fieldType !== type;
        }
        else if(type === 'productType') {
          if(fieldName === 'subCatId') {
            return tag.fieldType !== type;
          } else if(fieldName === 'thirdCatId') {
            return tag.level !== level && tag.level !== 4;
          }
          return tag.level !== level;
        } else if(type === 'industryType') {
          if(fieldName === 'industryFirstCatId') {
            return tag.fieldType !== type;
          }
          return tag.level !== level;
        }
      });
      this.tagList = [...tagList];
    },
    initValue(type = 'all', fieldName) {
      this.currentPage = 1;
      if(type === 'all') {
        this.brandId = 0;
        this.softId = 0;
        this.subCatId = 0;
        this.thirdCatId = 0;
        this.fourCatId = 0;
        this.industryFirstCatId = 0;
        this.industrySecordCatId = 0;
        // this.key = '';
      } else if(type === 'brandType') {
        this.brandId = 0;
      } else if(type === 'softType') {
        this.softId = 0;
      } else if(type === 'productType') {
        if(fieldName === 'subCatId') {
          this.subCatId = 0;
          this.thirdCatId = 0;
        } else if(fieldName === 'thirdCatId') {
          this.thirdCatId = 0;
        }
        this.fourCatId = 0;
      } else if(type === 'industryType') {
        if(fieldName === 'industryFirstCatId') {
          this.industryFirstCatId = 0;
        }
        this.industrySecordCatId = 0;
      }
    },
    deleteSearchText() {
      this.key = '';
      this.getCanvasList();
    },
  },
  mounted() {
    let { companyId } = this.$route.query;
    this.companyId = companyId;
    this.getPDFList();
  },
  computed: {
  },
}
</script>
<style scoped>
@import '../style/pdf.css';
</style>
