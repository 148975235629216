import { stringify } from 'qs';

export const baseUrl = 'https://andrnd.com';

// 取Local
export function getLocal(key) {
	let val = ''
	try {
		val = JSON.parse(localStorage.getItem(key))
	} catch (e) {
		// empty
	}
	return val
}

/**
 * 下载文件
 * @param url 接口路径
 * @param data 请求的参数
 * @param method 请求方式
 * @param type 下载文件的类型
 * @param fileName 自定义文件名称
 */
export const download = (
  url,
  data,
  method,
  type,
  fileName,
) => {
  /** fetch 配置项 */
  const params = {
    method,
    headers: {
      Authorization: '',
      'response-type': 'arraybuffer',
    },
  };
  if (method === 'GET') {
    // 每次请求添加时间戳，避免 GET 请求遭遇 HTTP 缓存
    data._ = new Date().getTime();
    // 请求参数合并到 URL 上
    url += `?${stringify(data)}`;
  } else {
    params.body = JSON.stringify(data);
  }
  return fetch(url, params)
	.then((res) => {
		console.log(res, 'rrrrr')
		// if (res.status === 200 && res.body) {
		// 	if (!fileName) {
		// 		const cd = res.headers.get('content-disposition');
		// 		const cds = cd && cd.split('filename=') || [];
		// 		if (cds[1]) {
		// 			fileName = decodeURIComponent(cds[1]);
		// 		}
		// 	}
		// 	return res.blob();
		// }
		// return Promise.reject();
	})
	.then((_blob) => {
		const blob = new Blob([_blob], {
			type,
		});
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName || 'file';
		link.click();
		return 'done';
	});
};