import request from '../request'

export function getNewsInfoList(params) {
  return request.post({
    url: '/industry-bs/reception/pc/newsInfo/newsInfoList',
    params
  });
}
export function getNewsDetail(params) {
  return request.post({
    url: '/industry-bs/reception/pc/newsInfo/newsDetail',
    params
  })
}
