import { render, staticRenderFns } from "./videoList.vue?vue&type=template&id=32709924&scoped=true&"
import script from "./videoList.vue?vue&type=script&lang=js&"
export * from "./videoList.vue?vue&type=script&lang=js&"
import style0 from "./videoList.vue?vue&type=style&index=0&id=32709924&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32709924",
  null
  
)

export default component.exports