import request from '../request'

// 注册
export function Register(params) {
  return request.post({
    url: '/industry-bs/reception/userLogin/register',
    params
  });
}

// 获取验证码
export function GetCode(params) {
  return request.post({
    url: '/industry-bs/reception/userLogin/sendCode',
    params
  });
}

// 密码登陆
export function LoginByPwd(params) {
  return request.post({
    url: '/industry-bs/reception/userLogin/passWordLogin',
    params
  });
}

// 验证码登陆
export function LoginByCode(params) {
  return request.post({
    url: '/industry-bs/reception/userLogin/verificationCodeLogin',
    params
  });
}

// 用户详情
export function GetUserInfo(params) {
  return request.post({
    url: '/industry-bs/reception/user/get',
    params
  });
}

// 重置密码
export function ResetPwd(params) {
  return request.post({
    url: '/industry-bs/reception/user/forgetPassWord',
    params
  });
}

// 是否注册用户
export function IsRegister(params) {
  return request.post({
    url: '/industry-bs/reception/user/isRegisterUser',
    params
  });
}
